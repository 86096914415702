import React from 'react';

import { CurrencySelector } from '@components/components';
import { useCountry } from '@components/components/CurrencySelector/useCountry';
import cx from 'clsx';

import { ChevronDown as ChevronDownIcon } from '../../../../public/images/icons/icons';

import Styles from './HeaderCurrencySelector.module.scss';

interface OwnProps {
  transparent?: boolean;
}

export const HeaderCurrencySelector: React.FC<OwnProps> = ({ transparent }) => {
  const [selectedCountry] = useCountry();

  const headerCurrencySelectorClassNames = cx({
    [Styles.wrapper]: true,
    [Styles.transparent]: !!transparent,
  });

  return (
    <CurrencySelector transparent={transparent}>
      {({ renderCurrencyList, opened, toggleOpen, ref }) => {
        return (
          <div ref={ref} className={headerCurrencySelectorClassNames}>
            {opened && <div className={Styles.container}>{renderCurrencyList('currency')}</div>}
            <div className={Styles.choosen} onClick={toggleOpen}>
              {selectedCountry.currency}
              <ChevronDownIcon height="12" />
            </div>
          </div>
        );
      }}
    </CurrencySelector>
  );
};
