import React, { useEffect, useRef, useState } from 'react';

import { ProductItem } from '@components/components';
import { GridLink } from '@components/renderers/ContentfulPagesRenderer/components/YouMayAlsoLike/components';
import { trackGA4ProductList } from '@core-analytics/getAnalyticsFunctions';
import { getProductListJSON } from '@core-analytics/schemas';
import cx from 'clsx';
import { useRouter } from 'next/router';

import type { OwnProps, Product } from './types';

import Styles from './YouMayAlsoLike.module.scss';

const GridHeader: React.FC<any> = () => <span>GridHeader...</span>;

export const YouMayAlsoLike: React.FC<OwnProps> = ({ parameters, onImageSelect, selectedIndex }) => {
  const { products, gridConfigs, headerIcon, headerText, textColor, isSwiper, className, shopTheLook, link, ...rest } =
    parameters;
  const { productItemClassName, youMayAlsoLikeClassName } = className;
  const router = useRouter();
  const [productsAnalytics, setProductsAnalytics] = useState('');

  const productListNameRef = useRef();

  useEffect(() => {
    if (products?.length > 0) {
      const analytics = getProductListJSON(products, router.asPath);
      setProductsAnalytics(analytics);
    }
  }, [products]);

  useEffect(() => {
    if (productsAnalytics && productsAnalytics.length > 0) {
      trackGA4ProductList(true, productListNameRef.current);
    }
  }, [productsAnalytics]);

  const renderProductsWithoutGrids = (products: Product[]): React.ReactNode => {
    return products.map((product, index) => {
      const selectedProduct = selectedIndex === index;

      return (
        <div
          className={Styles.imageWrapper}
          key={`${product.sku}-${product.uri}`}
          onClick={() => onImageSelect && onImageSelect(index)}
        >
          <ProductItem
            {...{
              product,
              selectedProduct,
              shopTheLook,
              noIcon: true,
              className: productItemClassName,
              noInfo: false,
              ...rest,
            }}
          />
        </div>
      );
    });
  };

  if (products && products.length > 0) {
    return (
      <>
        <div className={cx(Styles.youMayAlsoLike, youMayAlsoLikeClassName)} style={{ color: textColor }}>
          {headerText && <GridHeader text={headerText} icon={headerIcon} />}

          <div className={Styles.products}>
            <div className={Styles.noSwiperProductsWrapper}>{renderProductsWithoutGrids(products)}</div>
          </div>
          <GridLink url={link && link.url ? link.url : '/'} linkText={link && link.linkText ? link.linkText : ''} />
        </div>
        {productsAnalytics && productsAnalytics.length > 0 && (
          <div ref={productListNameRef} data-analytics-category="productPromoTripple">
            <script data-analytics="product-list" type="application/json">
              {productsAnalytics}
            </script>
          </div>
        )}
      </>
    );
  }
  return null;
};
