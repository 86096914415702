import React, { useState } from 'react';

import NextLink from 'next/link';

import type { OwnProps } from './types';

const isExternalUrl = (url: string) => {
  return url && (url.indexOf(':') > -1 || url.indexOf('//') > -1 || url.indexOf('www') > -1);
};

export const Link: React.FC<React.PropsWithChildren<OwnProps>> = props => {
  const [lang, setLang] = useState(false);

  const { onClick, color, width, fontSize } = props;
  const {
    linkToSameSite,
    to,
    staticContext: _,
    dispatch: __,
    changeLanguage: ___,
    isExternal = isExternalUrl(to),
    ...rest
  } = props;
  const url = isExternal ? `${to}` : to;
  const style = { color, width, fontSize };

  if (!url) {
    return null;
  }

  const hiddenState = (event: any) => {
    setLang(!lang);
    onClick && onClick(event);
  };

  if (isExternal) {
    const text = props.children || '';
    return (
      <a href={url} style={style} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  }

  return (
    <NextLink legacyBehavior href={url.charAt(0) !== '/' ? `/${url}` : url}>
      <a {...rest} onClick={hiddenState} style={style} />
    </NextLink>
  );
};
