import { cookieService } from '@utils/CookieService';

import { Product } from '../types/centra-api';

const priceForCurrentPricelist = (product: Product) => {
  const cookiePricelistId = cookieService.getPriceList();
  return product?.prices.find(({ pricelistId }) => pricelistId === cookiePricelistId);
};

export { priceForCurrentPricelist };
