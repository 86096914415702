import React, { Fragment } from 'react';

import { avaliableBlocks } from '@core-utils/avaliableBlocks';
import snakeCase from 'lodash/snakeCase';
import dynamic from 'next/dynamic';

import { Hero, Hero50 } from './components';
import type { OwnProps } from './types';

const {
  instagram,
  textintro,
  centraProducts,
  textbar,
  accordions,
  promo50,
  promo50small,
  spacer,
  featuredproduct,
  texteditor,
  hero,
  hero50,
  hero50textimage,
  panorama,
  panorama50,
  tripplepromo,
  tripplepromotext,
  textquote,
} = avaliableBlocks;

const componentList: Record<any, any> = {
  [centraProducts]: dynamic(() => import(`./components/CentraProducts/CentraProducts`)),
  [hero]: Hero,
  [panorama]: dynamic(() => import(`./components/Panorama/Panorama`)),
  [spacer]: dynamic(() => import(`./components/Spacer`)),
  [tripplepromotext]: dynamic(() => import(`./components/TripplePromoText/TripplePromoText`)),
  [textbar]: dynamic(() => import(`./components/TextBar/TextBar`)),
  [promo50]: dynamic(() => import(`./components/Promo50/Promo50`)),
  [hero50textimage]: dynamic(() => import(`./components/Hero50TextImage/Hero50TextImage`)),
  [tripplepromo]: dynamic(() => import(`./components/TripplePromo/TripplePromo`)),
  [hero50]: Hero50,
  [panorama50]: dynamic(() => import(`./components/Panorama50/Panorama50`)),
  [promo50small]: dynamic(() => import(`./components/Promo50Small/Promo50Small`)),
  [textintro]: dynamic(() => import(`./components/TextIntro/TextIntro`)),
  [textquote]: dynamic(() => import(`./components/TextQuote/TextQuote`)),
  [instagram]: dynamic(() => import(`./components/Instagram/Instagram`)),
  [featuredproduct]: dynamic(() => import(`./components/FeaturedProduct/FeaturedProduct`)),
  [texteditor]: dynamic(() => import(`./components/TextEditor/TextEditor`)),
  [accordions]: dynamic(() => import(`./components/Accordions/Accordions`)),
};

export const renderGallery = (props: OwnProps, gallery: any, index: number) => {
  const { availableModules } = props;
  const { __typename, type } = gallery;
  const blockType = type || __typename;

  const hasAvailableModules = availableModules?.includes(blockType);

  if (availableModules && !hasAvailableModules) {
    console.log(`Content models of type "${blockType}" are not allowed here. That's why we're just hiding them.`);
    return null;
  }

  const blockProps = {
    id: gallery.slug,
    module: gallery,
    ...(index === 0 && { priority: true }),
  };

  const Component = componentList[blockType];

  if (!Component) {
    console.log('Sorry, no module type:', type);
    return null;
  }

  return <Component {...blockProps} key={`${snakeCase(blockType)}-${index}`} />;
};

export const ContentfulPagesRenderer: React.FC<OwnProps & any> = props => {
  const { page } = props;
  const { galleries } = page;

  if (!Array.isArray(galleries)) {
    console.log('No galleries added in Contentful.');
    return null;
  }

  return <Fragment>{galleries.map((gallery: any, index) => renderGallery(props, gallery, index))}</Fragment>;
};

export default ContentfulPagesRenderer;
