import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Button } from '@components/ui/button';
import { Sheet, SheetContent } from '@components/ui/sheet';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import PaymentIcons from 'assets/icons/Payment';
import { CheckIcon } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useCartStore } from '../../../store/cartStore';
import { Spinner } from '../Spinner';

import { CART_QUERY } from './CartQuery';
import { EmptyCart } from './EmptyCart';
import { Products } from './Products';
import { Summary } from './Summary';
import { CartQuery } from './types';

export const Cart = () => {
  const { isCartOpen, setCartOpen } = useCartStore();
  const { data, loading } = useQuery<{ selection: CartQuery }>(CART_QUERY);

  const hasItems = !loading && !!data?.selection?.selection?.items?.length;
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setCartOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, setCartOpen]);

  return (
    <Sheet open={isCartOpen} onOpenChange={setCartOpen}>
      <SheetContent showClose={false} side="right" className="flex w-full flex-col p-8 md:max-w-md">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-bold uppercase">Your cart</h3>
          <SheetPrimitive.Close className="p-0 focus:outline-none disabled:pointer-events-none">
            <span className="text-sm font-bold uppercase tracking-wider">Close</span>
          </SheetPrimitive.Close>
        </div>
        {loading && <Spinner />}
        {!hasItems && <EmptyCart />}
        {hasItems && (
          <div className="flex flex-1 flex-col space-y-4 overflow-hidden lg:space-y-8">
            <div className="flex-1 overflow-auto">
              <Products products={data.selection.selection?.items} />
            </div>
            <hr className="border-t border-black/10" />

            {data.selection.selection.totals && <Summary totals={data.selection.selection.totals} />}

            <div className="space-y-4">
              <Link href="/selection" passHref legacyBehavior>
                <Button asChild>
                  <a>Go to checkout</a>
                </Button>
              </Link>
              <SheetPrimitive.Close asChild>
                <Button variant="outline">Continue shopping</Button>
              </SheetPrimitive.Close>
              <div className="flex items-center justify-between text-sm">
                <div className="flex items-center gap-2">
                  <CheckIcon strokeWidth={1.5} className="h-[1em] w-[1em]" />
                  Worldwide shipping
                </div>
              </div>
              <PaymentIcons className="mx-auto h-4 grayscale" />
            </div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};
