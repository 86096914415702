import React from 'react';

import { ContentfulPagesRenderer } from '@components/renderers/ContentfulPagesRenderer';
import { avaliableBlocks } from '@core-utils/avaliableBlocks';
import type { OwnProps } from 'Divider';

import EditorialPagesStyles from '@components/pages/EditorialPage/EditorialPage.module.scss';

export const Divider: React.FC<OwnProps> = ({ data }) => {
  const {
    instagram,
    textintro,
    centraProducts,
    textbar,
    promo50,
    promo50small,
    spacer,
    featuredproduct,
    texteditor,
    hero,
    hero50,
    hero50textimage,
    panorama,
    panorama50,
    tripplepromo,
    tripplepromotext,
    textquote,
  } = avaliableBlocks;
  const params = {
    page: { galleries: [data] },
    className: EditorialPagesStyles,
    imageType: 'standard',
    noHoverAction: true,
    isSwiper: true,
    avaliableModules: [
      textbar,
      promo50,
      promo50small,
      spacer,
      featuredproduct,
      texteditor,
      hero,
      hero50,
      hero50textimage,
      panorama,
      panorama50,
      tripplepromo,
      tripplepromotext,
      texteditor,
      centraProducts,
      instagram,
      textintro,
      textquote,
    ],
  };

  return <ContentfulPagesRenderer {...params} />;
};
