import React, { useMemo } from 'react';

import { isBrowser } from '@core-utils/global';
import WaitForReact from '@moxy/react-wait-for-react';
import cx from 'clsx';

import { Logo as LogoIcon } from '../../../public/images/icons';

import * as Styles from './Loading.module.scss';

type OwnProps = {};

export const Loading: React.FC<OwnProps & JSX.IntrinsicElements['div']> = props => {
  const { className, ...rest } = props;

  const preloadAssets = async () => {
    // Preload files, like a mp3, 3d objects, etc..
  };

  const promise = useMemo(() => preloadAssets(), []);
  const applyProgressBeforeInteractive = `function (elements, progress) {
    elements.splashScreen.classList.add('${Styles.loading}');
  }`.replace(/\n\s*/gm, ' ');

  return (
    <WaitForReact
      progressInterval={250}
      applyProgressBeforeInteractive={applyProgressBeforeInteractive}
      promise={promise}
    >
      {({ progress }) => {
        if (isBrowser()) {
          if (progress < 1) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.removeAttribute('style');
          }
        }

        return (
          <div
            data-wait-for-react-element="splashScreen"
            className={cx({
              [Styles.loading]: true,
              [Styles.done]: progress >= 1,
              ...(className && { [className]: true }),
            })}
            {...rest}
          >
            <img
              height={Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)}
              width={Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)}
              src="/images/loading/Rectangle_1_lrc7yr_c_scale,w_767.jpg"
            />
            <div className={Styles.container}>
              <LogoIcon />
              <div className={Styles.header}>Loading...</div>
            </div>
          </div>
        );
      }}
    </WaitForReact>
  );
};
