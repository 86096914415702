import { Fragment } from 'react';

import type {
  CategoryData,
  CustomElementData,
  PageType,
} from '@components/renderers/ContentfulPagesRenderer/components/NavigationBuilder/NavigationBuilderTypes';
import { Link } from '@core-frontend/components';
import cx from 'clsx';

import Styles from '../DesktopNavigation/DesktopNavigation.module.scss';

export const renderMenuElementCategory = (
  elemetCategory: string,
  categoriesList: CategoryData[] = [],
  activeMenuItems?: string[],
  allowEmpty?: boolean,
) => {
  return (
    <Fragment>
      {categoriesList.map((category: CategoryData) => {
        if (category.category === elemetCategory) {
          const active = activeMenuItems?.indexOf(category.name[category.name.length - 1].toLowerCase()) !== -1;
          return (
            <Link to={`/${category.uri}`} key={category.category}>
              <span
                className={cx({
                  [Styles.active]: active,
                  [Styles.menuItem]: true,
                })}
              >
                {category.name[category.name.length - 1].toLocaleLowerCase()}
              </span>
            </Link>
          );
        }
        return null;
      })}
    </Fragment>
  );
};

export const renderMenuElementPage = (
  menuElement: string,
  pagesList: PageType[] = [],
  activeMenuItems?: string[],
  allowEmpty?: boolean,
) => {
  return (
    <Fragment>
      {pagesList.map((page: PageType) => {
        if (page.slug === menuElement) {
          const active = activeMenuItems?.indexOf(page.slug) !== -1;
          return (
            <Link to={`/page/${page.slug}`} key={page.slug}>
              <span
                className={cx({
                  [Styles.active]: active,
                  [Styles.menuItem]: true,
                })}
              >
                {page.pageTitle.toLocaleLowerCase()}
              </span>
            </Link>
          );
        }
        return null;
      })}
    </Fragment>
  );
};

export const renderMenuElementCustom = (
  menuElement: CustomElementData | undefined,
  activeMenuItems?: string[],
  allowEmpty?: boolean,
) => {
  if (menuElement && menuElement.emptySpaceOnly) {
    return <div style={{ height: '44px' }} />;
  }
  if (!menuElement.uri && allowEmpty) {
    return <span>{menuElement.name}</span>;
  }
  if (menuElement && menuElement.uri && menuElement.uri.includes('http')) {
    return (
      <a
        href={menuElement.uri}
        target="_blank"
        rel="noopener noreferrer"
        {...(menuElement.color && { style: { color: menuElement.color } })}
      >
        <span>{menuElement.name}</span>
      </a>
    );
  }
  const active = activeMenuItems?.indexOf(menuElement.uri) !== -1;
  return menuElement ? (
    <Link to={menuElement.uri} {...(menuElement.color && { color: menuElement.color })}>
      <span
        className={cx({
          [Styles.active]: active,
          [Styles.menuItem]: true,
        })}
      >
        {menuElement.name.toLocaleLowerCase()}
      </span>
    </Link>
  ) : null;
};
