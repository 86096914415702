import React, { Fragment } from 'react';
import { forceCheck } from 'react-lazyload';

import { OnDesktopOnly } from '@components/layout-components';
import { NavigationBuilder } from '@components/renderers/ContentfulPagesRenderer/components';
import { findCustomElement } from '@components/renderers/ContentfulPagesRenderer/components/NavigationBuilder/NavigationBuilderHelpers';
import type { MenuElement } from '@components/renderers/ContentfulPagesRenderer/components/NavigationBuilder/NavigationBuilderTypes';
import cx from 'clsx';
import type { OwnProps } from 'DesktopNavigation';
import { useRouter } from 'next/router';

import {
  renderMenuElementCategory,
  renderMenuElementCustom,
  renderMenuElementPage,
} from '../NavigationBuilderList/renderer';

import Styles from './DesktopNavigation.module.scss';

export const DesktopNavigation: React.FC<OwnProps> = () => {
  const desktopNavigationWrapperStyles = cx(Styles.wrapper);
  const { asPath } = useRouter();
  const activeMenuItems = asPath.split('/');
  return (
    <OnDesktopOnly>
      <div key={`navigation-builder`} className={desktopNavigationWrapperStyles}>
        <NavigationBuilder slug="desktop-navigation">
          {({ categoryTreeItems, categories, cfPages, customItems, navigationMedia: media = [] }) => {
            const createLink = (item: MenuElement, allowEmpty?: boolean) => (
              <Fragment>
                {item.type === 'category' &&
                  renderMenuElementCategory(item.category, categories, activeMenuItems, allowEmpty)}
                {item.type === 'page' && renderMenuElementPage(item.category, cfPages, activeMenuItems, allowEmpty)}
                {item.type === 'customLink' &&
                  renderMenuElementCustom(findCustomElement(item.category, customItems), activeMenuItems, allowEmpty)}
              </Fragment>
            );

            const buildItemsList = (items: MenuElement[]) => {
              return (
                <Fragment>
                  {items.map((item, index) => {
                    return (
                      <li key={`desktop-navigation-item-${index}`} className={Styles.column}>
                        {createLink(item)}
                      </li>
                    );
                  })}
                </Fragment>
              );
            };

            return (
              <ul className={Styles.list}>
                {categoryTreeItems.map((item, index) => {
                  if (item.items && item.items.length > 0) {
                    return (
                      <Fragment key={`desktop-navogation-list-${index}`}>
                        <li onMouseEnter={() => forceCheck()} className={Styles.item}>
                          {createLink(item)}
                          <div className={Styles.menu}>
                            <ul className={Styles.columns}>{buildItemsList(item.items)}</ul>
                          </div>
                        </li>
                      </Fragment>
                    );
                  }

                  return (
                    <li key={`desktop-navogation-list-${index}`} className={Styles.item}>
                      {createLink(item)}
                    </li>
                  );
                })}
              </ul>
            );
          }}
        </NavigationBuilder>
      </div>
    </OnDesktopOnly>
  );
};
