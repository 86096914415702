import React, { Fragment } from 'react';

import { OnDesktopOnly, OnMobileOnly } from '@components/layout-components';

import type { OwnProps } from './types';
import { returnGrid } from './utils';

export const Multigrid: React.FC<OwnProps> = ({ gridConfigs, data, renderer }) => {
  const { gridConfig, mobileGridConfig } = gridConfigs;
  const desktopConfig = gridConfig && JSON.parse(gridConfig);
  const mobileConfig = mobileGridConfig && JSON.parse(mobileGridConfig);

  return (
    <Fragment>
      {desktopConfig && <OnDesktopOnly>{returnGrid(data, desktopConfig, renderer)}</OnDesktopOnly>}
      {mobileGridConfig && <OnMobileOnly>{returnGrid(data, mobileConfig, renderer)}</OnMobileOnly>}
    </Fragment>
  );
};
