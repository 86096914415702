import { gql } from '@apollo/client';

export const MINICART_BUTTON_QUERY = gql`
  query GetMniCartData {
    selection {
      token
      selection {
        items {
          item
          quantity
        }
      }
    }
  }
`;
