import React from 'react';

import cx from 'clsx';
import { useRouter } from 'next/router';
import Link from 'next/link';

import type { OwnProps } from './types';

export const NavLink: React.FC<React.PropsWithChildren<OwnProps>> = props => {
  const { pathname } = useRouter();
  const { activeClassName, to, children, linkToSameSite, onClick } = props;
  if (to && to.includes('#')) {
    const hlink = `#${to.split('#')[1]}`;
    if (linkToSameSite) {
      return (
        <Link legacyBehavior href={hlink} locale={false}>
          <a onClick={onClick}>{children}</a>
        </Link>
      );
    }

    return (
      <Link legacyBehavior href={to} locale={false}>
        <a onClick={onClick}>{children}</a>
      </Link>
    );
  }

  return (
    <Link legacyBehavior href={to} locale={false}>
      <a
        className={cx({
          [activeClassName]: pathname === to,
        })}
      >
        {children}
      </a>
    </Link>
  );
};
