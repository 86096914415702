import React, { useState } from 'react';

import { Block } from '@components/components';
import { RichText } from '@components/renderers/ContentfulPagesRenderer/components';
import { Accordion, AccordionsGroup } from '@core-frontend/components';

import { Minus as MinusIcon, Plus as PlusIcon } from '../../../../../public/images/icons/icons';

import Styles from './Accordions.module.scss';

export type OwnProps = {
  module: {
    title: string;
    headerText?: string;
    items?: {
      content: string | null;
      headerText: string;
    }[];
  };
  priority?: boolean;
};

export const Accordions: React.FC<JSX.IntrinsicElements['div'] & OwnProps> = ({ module, priority, id }) => {
  if (!module) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [toggle, setToggle] = useState(-1);

  const checkExpandAccordion = (index: number) => () => {
    if (toggle === index) {
      if (toggle === index) {
        setToggle(-1);
      }
    } else {
      setToggle(index);
    }
  };

  const { headerText, items } = module;

  return (
    <Block width={'container'} theme={'light'} id={id}>
      <AccordionsGroup className={Styles.wrapper}>
        {headerText && <h2 className={Styles.header}>{headerText}</h2>}
        {items?.map((accordion, index) => {
          const { headerText, content } = accordion;
          const contentText = content && JSON.parse(content);
          return headerText && content ? (
            <div className={Styles.accordionGroup} key={`accordion-group-item-${index}`}>
              <Accordion
                expanded={toggle === index}
                onToggle={checkExpandAccordion(index)}
                title={headerText}
                expandButton={() => <PlusIcon />}
                collapseButton={() => <MinusIcon />}
              >
                <div className={Styles.description}>{RichText(contentText)}</div>
              </Accordion>
            </div>
          ) : null;
        })}
      </AccordionsGroup>
    </Block>
  );
};

export default Accordions;
