import type { ReactElement } from 'react';
import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useCountry } from '@components/components/CurrencySelector/useCountry';
import { useClickOutsideRef } from '@utils/global';
import cx from 'clsx';

import { UPDATE_COUNTRY_MUTATION } from './CountryQuery';
import { updateCountry as updateCountryMutation } from './countryUpdate';

import Styles from './CurrencySelector.module.scss';

interface OwnProps {
  transparent?: boolean;
  children: (data: {
    renderCountryList: (parameter: string) => any;
    renderCurrencyList: (parameter: string) => any;
    toggleOpen: () => any;
    ref: React.MutableRefObject<HTMLDivElement>;
    opened: boolean;
  }) => ReactElement;
}

export const CurrencySelector: React.FC<OwnProps> = ({ children }) => {
  const { ref } = useClickOutsideRef<HTMLDivElement>(() => {
    setOpen(false);
  });

  const [opened, setOpen] = useState(false);
  const [changeCountry] = useMutation(UPDATE_COUNTRY_MUTATION);
  const [selectedCountry, currencyData] = useCountry();

  if (!currencyData?.countries.length) {
    return null;
  }

  const toggleOpen = () => {
    setOpen(!opened);
  };

  const chooseCountry = (name: string, param: 'currency' | 'country' | 'name') => () => {
    const selectedCountry: { country: string; name: string; currency: string } = currencyData.countries.find(
      element => element[param] === name,
    );
    setOpen(false);
    updateCountryMutation(selectedCountry.country, changeCountry);
  };

  const renderCountryList = (parameter: string) => {
    return currencyData.countries.map((element, index) => {
      return (
        <div
          key={`currency-list-item-${parameter}-${index}`}
          onClick={chooseCountry(element.country, 'country')}
          className={selectedCountry.country === element.country ? cx(Styles.market, Styles.active) : Styles.market}
        >
          {element[parameter]}
        </div>
      );
    });
  };

  const renderCurrencyList = (parameter: string) => {
    const currenciesList = currencyData.countries.reduce((acc, current) => {
      const { currency } = current;
      if (acc.includes(currency)) {
        return acc;
      }

      return [...acc, currency];
    }, []);

    return currenciesList.map((element, index) => {
      return (
        <div
          key={`currency-list-item-${parameter}-${index}`}
          onClick={chooseCountry(element, 'currency')}
          className={selectedCountry.currency === element ? cx(Styles.market, Styles.active) : Styles.market}
        >
          {element}
        </div>
      );
    });
  };

  return children({
    renderCountryList,
    renderCurrencyList,
    toggleOpen,
    opened,
    ref,
  });
};
