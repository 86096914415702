import React, { useCallback, useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import { MAX_LAYOUT_WIDTH } from '@core-utils/constants';
import { useCurrentWidth } from '@utils/hooks';
import cx from 'clsx';

import type { OwnProps } from './types';

import Styles from './MediaIframe.module.scss';

export const MediaIframe: React.FC<JSX.IntrinsicElements['div'] & OwnProps> = ({
  media,
  className,
  style,
  priority,
  ...rest
}) => {
  const { iframe, mobileAspect, desktopAspect } = media || {};
  const containerRef = useRef(null);
  const [componentWidth, setComponentWidth] = useState();
  const windowWidth = useCurrentWidth();

  const imageWidth = componentWidth || MAX_LAYOUT_WIDTH;

  const wrapperRef = useCallback(node => {
    if (node) {
      containerRef.current = node;
      setComponentWidth(node.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setComponentWidth(containerRef.current.offsetWidth);
    }
  }, [windowWidth]);

  if (!iframe) {
    return null;
  }

  const mediaIframeClassNames = cx(Styles.container, {
    ...(className && { [className]: true }),
  });

  const regextIframe = /<iframe[^>]*(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
  const foundIframe = iframe.match(regextIframe)!.length > 0;
  const foundVimeo = iframe.includes('https://player.vimeo.com/video');
  const foundYouTube = iframe.includes('https://www.youtube');
  const foundSpotify = iframe.includes('https://open.spotify.com');
  const [aspectMobileWidth, aspectMobileHeight] = (mobileAspect || '1:1').split(':');
  const [aspectDesktopWidth, aspectDesktopHeight] = (desktopAspect || '16:9').split(':');
  const finalMobileAspect = (parseInt(aspectMobileHeight) / parseInt(aspectMobileWidth)) * 100;
  const finalMobileHeight = parseInt(aspectMobileHeight) / parseInt(aspectMobileWidth);
  const finalDesktopAspect = (parseInt(aspectDesktopHeight) / parseInt(aspectDesktopWidth)) * 100;
  const finalDesktopHeight = parseInt(aspectDesktopHeight) / parseInt(aspectDesktopWidth);

  if (!foundIframe && (!foundVimeo || !foundSpotify || !foundYouTube)) {
    return null;
  }

  const props = {
    className: mediaIframeClassNames,
    dangerouslySetInnerHTML: { __html: iframe },
    ...rest,
  };

  const renderDesktopVideo = () => {
    return (
      <TransitionGroup>
        <CSSTransition timeout={500} classNames="fade" appear={true}>
          <div
            style={{
              width: '100%',
              height: `${imageWidth * finalDesktopHeight}px`,
              ...style,
              paddingTop: `${finalDesktopAspect}%`,
            }}
            {...props}
          />
        </CSSTransition>
      </TransitionGroup>
    );
  };

  const renderMobileVideo = () => {
    return (
      <TransitionGroup>
        <CSSTransition timeout={500} classNames="fade" appear={true}>
          <div
            style={{
              width: '100%',
              height: `${imageWidth * finalMobileHeight}px`,
              ...style,
              paddingTop: `${finalMobileAspect}%`,
            }}
            {...props}
          />
        </CSSTransition>
      </TransitionGroup>
    );
  };

  return (
    <div ref={wrapperRef} className={Styles.wrapper}>
      <OnMobileAndTabletOnly>
        {priority ? (
          renderMobileVideo()
        ) : (
          <LazyLoad height={imageWidth * finalMobileHeight} once={true}>
            {renderMobileVideo()}
          </LazyLoad>
        )}
      </OnMobileAndTabletOnly>
      <OnDesktopOnly>
        {priority ? (
          renderDesktopVideo()
        ) : (
          <LazyLoad height={imageWidth * finalDesktopHeight} once={true}>
            {renderDesktopVideo()}
          </LazyLoad>
        )}
      </OnDesktopOnly>
    </div>
  );
};
