import React, { Fragment, useEffect, useState } from 'react';

import { useHeaderTransparencyStore } from '@components/contexts/useTransparentHeaderStore';
import { IdleUntilUrgent } from '@utils/idle';
import cx from 'clsx';
import dynamic from 'next/dynamic';
import type { OwnProps } from 'PageHeader';

import { CheckoutLanguageOverrides } from '../../../i18n/checkout-language-overrides';
import { InfoButton } from '../InfoButton/InfoButton';
import {
  BackButton,
  Cart,
  CartInfo,
  DesktopNavigation,
  HamburgerButton,
  Logo,
  MinicartButton,
  MobileNavigation,
  SearchButton,
  StickyHeader,
} from '..';

import { HeaderCurrencySelector } from './HeaderCurrencySelector/HeaderCurrencySelector';

import Styles from './PageHeader.module.scss';

const CheckoutScript = dynamic(() => import('./../CheckoutScript/CheckoutScript'));

const logoSettings = {
  height: {
    desktop: {
      scrolled: 20,
      standard: 20,
    },
    mobile: {
      scrolled: 20,
      standard: 20,
    },
  },
  width: {
    desktop: {
      scrolled: 85,
      standard: 85,
    },
    mobile: {
      scrolled: 85,
      standard: 85,
    },
  },
};

export const PageHeader: React.FC<OwnProps> = ({
  menuToggle,
  menu: menuOpened,
  isSelectionPath,
  backgroundOnHover = false,
}) => {
  const { transparentHeader } = useHeaderTransparencyStore();

  const [activeSearch, setActiveSearch] = useState(false);

  const headerClassNames = cx({
    [Styles.stickyHeaderClassName]: true,
    [Styles.openedMenu]: menuOpened,
    [Styles.backgroundOnHover]: backgroundOnHover,
    [Styles.transparentHeader]: transparentHeader && !menuOpened,
    [Styles.solidHeader]: !transparentHeader || !backgroundOnHover,
  });

  const headerContentClassNames = cx({
    [Styles.content]: true,
    [Styles.selectionContent]: isSelectionPath,
  });

  return (
    <StickyHeader menuOpened={menuOpened} className={headerClassNames}>
      <div className={headerContentClassNames}>
        {isSelectionPath && (
          <Fragment>
            <BackButton />
            <CartInfo />
            <InfoButton />
          </Fragment>
        )}
        {!isSelectionPath && (
          <Fragment>
            <Logo menuOpened={menuOpened} sizes={logoSettings} className={Styles.logo} />
            <div className={Styles.nav}>
              {!activeSearch && <DesktopNavigation />}
              <HeaderCurrencySelector transparent={activeSearch} />
              <SearchButton
                onActivate={active => {
                  setActiveSearch(active);
                }}
              />
            </div>
            <MinicartButton transparent={activeSearch} />
            <HamburgerButton onClick={menuToggle} menuOpened={menuOpened} />
          </Fragment>
        )}
      </div>
      <MobileNavigation show={menuOpened} onToggle={menuToggle} />
      <Cart />
    </StickyHeader>
  );
};

export default PageHeader;
