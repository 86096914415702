import React, { useState } from 'react';

import { cn } from '@components/ui/utils';
import { useAddToCart, useRemoveFromCart } from '@utils/cart';
import Link from 'next/link';
import { toast } from 'sonner';

import { Spinner } from '../Spinner';

import { Item } from './types';

export interface ProductProps {
  product: Item;
}

export const ProductItem: React.FC<ProductProps> = ({ product }) => {
  const addToCart = useAddToCart();
  const removeFromCart = useRemoveFromCart();
  const [loading, setLoading] = useState(false);

  const handleAddToCart = async (quantity: number) => {
    setLoading(true);
    try {
      await addToCart(product.item, quantity);
    } catch (error) {
      toast.error('Could not add item to cart');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFromCart = async (quantity: number) => {
    setLoading(true);
    try {
      await removeFromCart(product.line, quantity);
    } catch (error) {
      toast.error('Could not remove item from cart');
    } finally {
      setLoading(false);
    }
  };

  return (
    <li key={product.item} className="relative">
      {loading && (
        <div className="absolute inset-0">
          <Spinner />
        </div>
      )}

      <div
        className={cn('flex w-full gap-4', {
          'pointer-events-none select-none opacity-50': loading,
        })}
      >
        <Link href={product.productUrl} legacyBehavior>
          <a>
            <img src={product.product.media.standard[0]} alt={product.product.name} className="w-24" />
          </a>
        </Link>
        <div className="my-1 flex flex-col">
          <Link href={product.productUrl} legacyBehavior>
            <a className="text-xs uppercase leading-normal">{product.product.name}</a>
          </Link>
          <p className="text-xs uppercase leading-normal">Color - {product.product.variantName}</p>
          <p className="text-xs uppercase leading-normal">Size - {product.size}</p>
          <div className="mt-auto flex items-center">
            <button
              className="p-2 text-sm text-black !outline-none"
              type="button"
              onClick={() => handleRemoveFromCart(1)}
            >
              -
            </button>
            <span className="text-xs">{product.quantity}</span>
            <button className="p-2 text-sm text-black !outline-none" type="button" onClick={() => handleAddToCart(1)}>
              +
            </button>
          </div>
          <button
            className="w-fit p-0 text-xs uppercase underline !outline-none"
            type="button"
            onClick={() => handleRemoveFromCart(product.quantity)}
          >
            Remove
          </button>
        </div>
        <div className="my-1 ml-auto text-right">
          {product.anyDiscount && <p className="text-xs text-black line-through">{product.priceEachBeforeDiscount}</p>}
          {product.quantity > 1 && (
            <p className="text-xs text-black">
              {product.quantity} x {product.priceEach}
            </p>
          )}
          <p className="text-xs text-black">{product.totalPrice}</p>
        </div>
      </div>
    </li>
  );
};
