import React, { useEffect, useRef, useState } from 'react';

import { Block, ProductItem } from '@components/components';
import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import { trackGA4ProductList } from '@core-analytics/getAnalyticsFunctions';
import { getProductListJSON } from '@core-analytics/schemas';
import cx from 'clsx';
import { useRouter } from 'next/router';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Styles from './ProductPromoTripple.module.scss';

interface OwnProps {
  header?: string;
  width?: 'fullscreen' | 'container';
  products: any[];
}

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);

const ProductPromoTripple: React.FC<OwnProps> = ({ products, header, width = 'container' }) => {
  const ProductPromoTrippleClassNames = cx({
    [Styles.container]: true,
  });
  const router = useRouter();
  const [productsAnalytics, setProductsAnalytics] = useState('');

  const productListNameRef = useRef();

  useEffect(() => {
    if (products?.length > 0) {
      const analytics = getProductListJSON(products, router.asPath);
      setProductsAnalytics(analytics);
    }
  }, [products]);

  useEffect(() => {
    if (productsAnalytics && productsAnalytics.length > 0) {
      trackGA4ProductList(true, productListNameRef.current);
    }
  }, [productsAnalytics]);

  const mobileConfig = {
    slidesPerView: 2.2,
    spaceBetween: 0,
    loop: false,
    noSwiping: true,
    ...(products.length >= 3 && {
      noSwiping: false,
      pagination: {
        // @ts-expect-error
        el: Styles.pagination,
        clickable: true,
      },
    }),
  };

  const desktopConfig = {
    slidesPerView: 3,
    spaceBetween: 0,
    loop: false,
    noSwiping: true,
    navigation: true,
    ...(products.length >= 4 && {
      noSwiping: false,
      pagination: {
        // @ts-expect-error
        el: Styles.pagination,
        clickable: true,
      },
    }),
  };

  return (
    <Block width={width} theme="light">
      <div className={ProductPromoTrippleClassNames}>
        {products.length > 0 && header && <h3 className={Styles.header}>{header}</h3>}
        <OnMobileAndTabletOnly>
          <Swiper {...mobileConfig}>
            {products.map((element, index) => (
              <SwiperSlide key={`product-promo-mobile-item-${index}`}>
                <ProductItem product={element} noHoverAction={true}></ProductItem>
              </SwiperSlide>
            ))}
          </Swiper>
        </OnMobileAndTabletOnly>
        <OnDesktopOnly>
          <Swiper {...desktopConfig}>
            {products.map((element, index) => (
              <SwiperSlide key={`product-promo-desktop-item-${index}`}>
                <ProductItem product={element} noHoverAction={true}></ProductItem>
              </SwiperSlide>
            ))}
          </Swiper>
        </OnDesktopOnly>
      </div>
      {productsAnalytics && productsAnalytics.length > 0 && (
        <div ref={productListNameRef} data-analytics-category="productPromoTripple">
          <script data-analytics="product-list" type="application/json">
            {productsAnalytics}
          </script>
        </div>
      )}
    </Block>
  );
};

export default ProductPromoTripple;
