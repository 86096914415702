// eslint-disable-next-line no-restricted-imports
import OriginalHotjar from '@hotjar/browser';

const hotjarSiteId = Number(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID);

type InitOpts = Parameters<typeof OriginalHotjar.init>[2];

const { init: originalInit, identify: originalIdentify, ...OriginalHotjarRest } = OriginalHotjar;

const customInit = (opts?: InitOpts): boolean => {
  // Bail if we're not in production
  if (process.env.NEXT_PUBLIC_SERVER_ENV !== 'production') {
    return false;
  }

  if (Number.isNaN(hotjarSiteId)) {
    console.warn(
      'Hotjar was not initialized because the site ID is not properly set.',
      process.env.NEXT_PUBLIC_HOTJAR_SITE_ID,
    );
  }

  const hotjarVersion = 6;

  return originalInit(hotjarSiteId, hotjarVersion, opts);
};

const CustomHotjar = {
  ...OriginalHotjarRest,
  init: customInit,
};

export { CustomHotjar as Hotjar };
