import { gql } from '@apollo/client';

export const CART_QUERY = gql`
  query GetCartData {
    selection {
      token
      selection {
        totals {
          itemsTotalPrice
          shippingPrice
          grandTotalPrice
          taxPercent
          grandTotalPriceTax
          totalDiscountPrice
        }
        items {
          item
          line
          quantity
          anyDiscount
          priceEach
          priceEachBeforeDiscount
          totalPrice
          totalPriceBeforeDiscount
          totalPriceAfterDiscount
          productUrl
          size
          product {
            name
            variantName
            media {
              standard
            }
          }
        }
      }
    }
  }
`;
