import { gql } from '@apollo/client';
import { ContactFormFragment } from '@components/fragments/ContactFormFragment';
import { LinkFragment } from '@components/fragments/LinkFragment';
import { MenusFragment } from '@components/fragments/MenusFragment';
import { MessageBarFragment } from '@components/fragments/MessageBarFragment';

export const pageFooterQuery = gql`
  query footerQuery {
    cookieConsent: cfMessageBars(q: { fields: { slug: "cookieConsent" } }) {
      ...MessageBarFragment
    }
    newsletterContent: cfContactForms(q: { fields: { slug: "newsletter-content" } }) {
      ...ContactFormFragment
    }
    cfMenus(q: { fields: { slug: "footer" } }) {
      slug
      title
      name
      menu {
        ...LinkFragment
        ...MenusFragment
      }
    }
    cfMedias(q: { fields: { slug: "mediaForNewsletter" } }) {
      slug
      desktopImage {
        title
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      mobileImage {
        title
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
    }
  }
  ${MenusFragment}
  ${LinkFragment}
  ${MessageBarFragment}
  ${ContactFormFragment}
`;

export const mutation = gql`
  mutation newsletterSubscription($email: String!, $country: String, $gender: String) {
    newsletterSubscription(email: $email, country: $country, gender: $gender) {
      token
      subscribed
    }
  }
`;
