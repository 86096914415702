import { gql } from '@apollo/client';

export const ContactFormFragment = gql`
  fragment ContactFormFragment on CfContactForm {
    slug
    headerText
    consentText
    consent
    content
  }
`;
