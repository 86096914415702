import React, { useRef } from 'react';

import { Newsletter } from '@components/components';
import { Link } from '@core-frontend/components';

import { useNewsletterStore } from '../../../../store/newsletterStore';
import type { ContactFormData, Media } from '../../../../types';
import type { ContentfulMenu, ContentfulMenuElement } from '../../../../types/contentful/menu';
import { FooterCurrencySelector } from '../FooterCurrencySelector/FooterCurrencySelector';

import Styles from './DesktopFooter.module.scss';

type OwnProps = {
  menu: ContentfulMenuElement[];
  newsletterMedias: Media;
  newsletterContent?: ContactFormData;
  newsletterSubscription?: (variables: {}) => Promise<any>;
  renderCookieModalButton: () => JSX.Element;
};

export const DesktopFooter: React.FC<OwnProps> = ({
  menu,
  newsletterSubscription,
  newsletterMedias,
  newsletterContent,
  renderCookieModalButton,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { toggleNewsletter } = useNewsletterStore();

  const contentfulMenus: ContentfulMenu[] = menu.flatMap(column => (column.__typename === 'CfMenus' ? [column] : []));

  const createLinks = menu => {
    return menu.map((el, index) => {
      if (el.url === '#newsletter') {
        return (
          <Link
            key={`newsletter-link-${index}`}
            to={el.url}
            onClick={event => {
              event.preventDefault();
              toggleNewsletter(true);
            }}
          >
            Newsletter
          </Link>
        );
      } else if (el.url === '#cookie') {
        return renderCookieModalButton();
      } else {
        return (
          <Link key={`newsletter-link-${index}`} to={el.url}>
            {el.linkText}
          </Link>
        );
      }
    });
  };

  return (
    <div className={Styles.wrapper} ref={wrapperRef}>
      <div className={Styles.linksWrapper}>
        {contentfulMenus.map((column, index) => {
          if (column.name.toLowerCase() === 'ships to') {
            return (
              <div key={`footer-link-${index}`} className={Styles.wrapperSelect}>
                <p className={Styles.header}>{column.name}</p>
                <FooterCurrencySelector />
              </div>
            );
          }
          return (
            <div key={index} className={Styles.links}>
              <p className={Styles.header}>{column.name}</p>
              {createLinks(column.menu)}
            </div>
          );
        })}
      </div>
      <div className={Styles.newsletterWrapper}>
        <Newsletter
          newsletterContent={newsletterContent}
          newsletterMedias={newsletterMedias}
          newsletterSubscription={newsletterSubscription}
        />
      </div>
    </div>
  );
};
