import React from 'react';

import { NavigationCurrencySelector } from '@components/components';
import { useCountry } from '@components/components/CurrencySelector/useCountry';
import {
  findCustomElement,
  getElementLink,
} from '@components/renderers/ContentfulPagesRenderer/components/NavigationBuilder/NavigationBuilderHelpers';
import type {
  CategoryData,
  CustomElementData,
  MenuElement,
  PageType,
} from '@components/renderers/ContentfulPagesRenderer/components/NavigationBuilder/NavigationBuilderTypes';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@components/ui/accordion';
import { cn } from '@components/ui/utils';

import type { Media } from '../../../types';
import { LazyImage } from '../LazyImage/LazyImage';

import { renderMenuElementCategory, renderMenuElementCustom, renderMenuElementPage } from './renderer';

interface NavigationBuilderListProps {
  treeItemsPath: number[];
  items: MenuElement[];
  customItems: CustomElementData[];
  pageAndCategories: {
    categories: CategoryData[];
    cfPages: PageType[];
  };
  navigationMedia: Media[];
  setActiveTabName?: (name: string) => void;
  onClick: () => void;
  openTab?: string;
}

const defaultLinkClassNames = 'font-sans text-sm font-semibold uppercase';

export const NavigationBuilderList = ({
  onClick,
  items,
  customItems,
  pageAndCategories,
  navigationMedia,
}: NavigationBuilderListProps) => {
  const [selectedCountry] = useCountry();

  const backgroundImage = navigationMedia[0];

  const createLink = (item: MenuElement) => (
    <>
      {item.type === 'category' && renderMenuElementCategory(item.category, pageAndCategories?.categories)}
      {item.type === 'page' && renderMenuElementPage(item.category, pageAndCategories?.cfPages)}
      {item.type === 'customLink' && renderMenuElementCustom(findCustomElement(item.category, customItems))}
    </>
  );

  const buildShippingList = (elementName: string = 'shipping') => (
    <ul>
      <li>
        <AccordionItem value={elementName}>
          <AccordionTrigger className={defaultLinkClassNames}>Ship to: {selectedCountry.name}</AccordionTrigger>
          <AccordionContent>
            <NavigationCurrencySelector />
          </AccordionContent>
        </AccordionItem>
      </li>
    </ul>
  );

  const buildList = (
    items: MenuElement[],
    customItems: CustomElementData[],
    parentPath: string,
    innerList: boolean = false,
  ) => {
    return (
      <ul className="border-t">
        {items.map((item: MenuElement, index) => {
          const name = getElementLink(item, customItems, pageAndCategories);
          const itemPath = `${parentPath}${index}`;
          const hasChildren = item.items && item.items.length > 0;

          if (hasChildren) {
            return (
              <li key={itemPath}>
                <AccordionItem value={name}>
                  <AccordionTrigger className={defaultLinkClassNames}>
                    <span
                      onClick={e => {
                        e.stopPropagation();
                        onClick();
                      }}
                    >
                      {createLink(item)}
                    </span>
                  </AccordionTrigger>
                  <AccordionContent>{buildList(item.items, customItems, `${itemPath}-`, true)}</AccordionContent>
                </AccordionItem>
              </li>
            );
          }
          return (
            <li
              key={itemPath}
              onClick={onClick}
              className={cn('font-sans text-sm uppercase [&>a]:block [&>a]:py-4', {
                'font-semibold [&>a]:border-b': !innerList,
                'ml-4': innerList,
              })}
            >
              {createLink(item)}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="container">
      <Accordion type="single" collapsible className="w-full">
        {buildList(items, customItems, '')}
        {buildShippingList()}
      </Accordion>
      {backgroundImage?.mobileImage?.file?.url && (
        <div className="mt-auto w-full flex-auto">
          <LazyImage
            sources={true}
            className="w-full"
            src={`${backgroundImage?.mobileImage?.file?.url}?fit=scale&w=${backgroundImage?.mobileImage?.file?.details?.image?.width}`}
            alt={backgroundImage?.mobileImage?.title || ''}
            width={backgroundImage?.mobileImage?.file?.details?.image?.width}
            height={backgroundImage?.mobileImage?.file?.details?.image?.height}
          />
        </div>
      )}
      <p className="mt-4 text-xs">© BLK DNM</p>
    </div>
  );
};
