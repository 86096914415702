import React from 'react';

import { CurrencySelector } from '@components/components';
import { useCountry } from '@components/components/CurrencySelector/useCountry';

import { ChevronDown as ChevronDownIcon } from '../../../../public/images/icons/icons';

import Styles from './FooterCurrencySelector.module.scss';

export const FooterCurrencySelector: React.FC = () => {
  const [selectedCountry] = useCountry();

  return (
    <CurrencySelector>
      {({ renderCountryList, toggleOpen, opened }) => {
        return (
          <div className={Styles.wrapper}>
            {opened && <div className={Styles.container}>{renderCountryList('name')}</div>}
            <div className={Styles.choosen} onClick={toggleOpen}>
              {selectedCountry.name}
              <ChevronDownIcon height="12" />
            </div>
          </div>
        );
      }}
    </CurrencySelector>
  );
};
