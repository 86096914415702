import React, { Fragment } from 'react';

import { AspectRatioDiv, Block, ImageBanner, MediaIframe, VideoContent } from '@components/components';
import { Link } from '@core-frontend/components';
import cx from 'clsx';

import type { Media } from '../../../../../types';
import type { RichContent } from '../../../../../types/contentful/RichContent';

import { Hero50TextImageMediaContent } from './components/Hero50TextImageMediaContent/Hero50TextImageMediaContent';

import Styles from './Hero50TextImage.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    image: Media;
    richContent?: RichContent | null;
    width?: 'fullscreen' | 'container';
    theme?: 'light' | 'dark';
    side?: 'left' | 'right';
  };
  priority?: boolean;
};

export const Hero50TextImage: React.FC<OwnProps> = ({ module, priority }) => {
  if (!module) {
    return null;
  }

  const { image, richContent, side, width, theme, slug } = module;
  const { links } = richContent || {};
  const linkUrl = links && links[0]?.url;
  const linkText = links && links[0]?.title;

  const renderMedia = (media: Media) => {
    const { desktopImage, mobileImage, video, iframe } = media || {};

    const containerClassNames = cx({
      [Styles.container]: true,
    });

    return (
      <div className={containerClassNames}>
        {video && <VideoContent media={media} />}
        {(desktopImage || mobileImage) && <ImageBanner priority={priority} media={media} />}
        {iframe && <MediaIframe media={media} />}
        {!desktopImage && !mobileImage && !iframe && !video && <AspectRatioDiv media={media} />}
      </div>
    );
  };

  const renderContent = (media: Media, content: RichContent) => {
    const containerClassNames = cx({
      [Styles.container]: true,
    });

    return (
      <div className={containerClassNames}>
        <AspectRatioDiv media={media} />
        {content && (
          <div className={Styles.content}>
            <Hero50TextImageMediaContent className={Styles.contentPadding} config={content} />
          </div>
        )}
      </div>
    );
  };

  const renderWrapper = () => (
    <div className={Styles.wrapper}>
      {side === 'left' ? (
        <Fragment>
          {renderMedia(image)}
          {renderContent(image, richContent)}
        </Fragment>
      ) : (
        <Fragment>
          {renderContent(image, richContent)}
          {renderMedia(image)}
        </Fragment>
      )}
    </div>
  );

  return (
    <Block width={width} theme={theme} id={slug}>
      {linkUrl ? (
        <Link className={Styles.containerLink} to={linkUrl} {...(linkText && { 'aria-label': linkText })}>
          {renderWrapper()}
        </Link>
      ) : (
        renderWrapper()
      )}
    </Block>
  );
};

export default Hero50TextImage;
