import React, { useEffect } from 'react';

import { getElementLink } from '@components/renderers/ContentfulPagesRenderer/components/NavigationBuilder/NavigationBuilderHelpers';
import type {
  CategoryData,
  CustomElementData,
  MenuElement,
  PageType,
} from '@components/renderers/ContentfulPagesRenderer/components/NavigationBuilder/NavigationBuilderTypes';

import type { Media } from '../../../types';
import { NavigationBuilderList } from '../NavigationBuilderList/NavigationBuilderList';

interface OwnProps {
  categoryTreeItems: MenuElement[];
  customItems: CustomElementData[];
  pageAndCategories: {
    categories: CategoryData[];
    cfPages: PageType[];
  };
  navigationMedia: Media[];
  setGallerySlug?: (name: string) => void;
  onClick: () => void;
}

export const NavigationBuilderMenu: React.FC<OwnProps> = ({
  categoryTreeItems,
  pageAndCategories,
  customItems,
  onClick,
  navigationMedia,
}) => {
  const [expanded, setExpanded] = React.useState('');
  const [treeItemsPath, setTreeItemsPath] = React.useState<number[]>([]);
  const [isChangedManually, setIsChangedManually] = React.useState(false);

  useEffect(() => {
    if (pageAndCategories && !isChangedManually) {
      let treeItemsPath: number[] = [];

      categoryTreeItems.forEach((element: MenuElement, firstIndex: number) => {
        if (element.items.length > 0) {
          element.items.forEach((subItem: MenuElement, secondIndex: number) => {
            if (getElementLink(subItem, customItems, pageAndCategories) === window.location.pathname) {
              treeItemsPath = [firstIndex, secondIndex];
            }

            if (subItem.items.length > 0) {
              subItem.items.forEach((subSubItem: MenuElement, thirdIndex) => {
                if (getElementLink(subSubItem, customItems, pageAndCategories) === window.location.pathname) {
                  treeItemsPath = [firstIndex, secondIndex, thirdIndex];
                }
              });
            }
          });
        }
      });

      if (treeItemsPath.length > 0) {
        setTreeItemsPath(treeItemsPath);
      }
    }
  }, [pageAndCategories, categoryTreeItems, customItems, isChangedManually]);

  return (
    <NavigationBuilderList
      pageAndCategories={pageAndCategories}
      items={categoryTreeItems}
      customItems={customItems}
      onClick={onClick}
      treeItemsPath={treeItemsPath}
      navigationMedia={navigationMedia}
    />
  );
};
