import { useQuery } from '@apollo/client';
import { cookieService } from '@utils/CookieService';

import { countryQuery } from './CountryQuery';

const findCountry = (currencyData: any) => {
  const country = cookieService.getCountryCode();

  if (currencyData?.countries) {
    const foundedCountry = currencyData?.countries.find(element => element.country === country);
    return {
      country: foundedCountry?.country || 'SE',
      name: foundedCountry?.name || 'Sweden',
      currency: foundedCountry?.currency || 'sek',
    };
  }
  return {
    country: null,
    name: null,
    market: null,
    currency: null,
  };
};

export function useCountry() {
  const { data: currencyData } = useQuery(countryQuery);
  const initial = findCountry(currencyData);

  return [initial, currencyData] as const;
}
