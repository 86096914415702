import type { ReactNode } from 'react';
import React, { Fragment, useEffect, useState } from 'react';

import { GAtrackVirtualPage, initTagManager } from '@core-analytics/getAnalyticsFunctions';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { isCheckoutRelatedPage } from '../utils/checkout';
import { Hotjar } from '../utils/hotjar';

import { TopPromoBanner } from './components/TopPromoBanner';
import { Toaster } from './ui/sonner';
import { Layout } from './components';

const PageFooter = dynamic(() => import('./components/PageFooter/PageFooter'));
const PageHeader = dynamic(() => import('./components/PageHeader/PageHeader'));

type Props = {
  children?: ReactNode;
  title?: string;
  transparentHeader?: boolean;
};

Hotjar.init();

const AppLayout = ({ children, title = 'BLK DNM', transparentHeader = false }: Props) => {
  const router = useRouter();
  const [menu, setMenu] = useState(false);
  const { pathname } = router;
  const isSelectionPath = isCheckoutRelatedPage(pathname);

  useEffect(() => {
    initTagManager();
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      GAtrackVirtualPage(url, window.document.title);
    };

    handleRouteChange(window.location.href);
  }, []);

  const menuToggle = () => {
    setMenu(prev => !menu);
  };

  const layoutClassNames = 'flex flex-col w-full';

  return (
    <Fragment>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="google-site-verification" content="pra5HM-Oq5G6-dxjakHebVC0dMXI6HCy44_v05Vwlas" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        {/* eslint-disable-next-line @next/next/no-sync-scripts */}
        <script
          data-culture="EN"
          data-gcm-enabled="true"
          id="CookieConsent"
          src="https://policy.app.cookieinformation.com/uc.js"
          type="text/javascript"
        ></script>
      </Head>
      <TopPromoBanner />
      <PageHeader
        menu={menu}
        menuToggle={menuToggle}
        isSelectionPath={isSelectionPath}
        backgroundOnHover={transparentHeader}
      />
      <Layout menu={menu} className={layoutClassNames}>
        {children}
      </Layout>
      {!isSelectionPath && <PageFooter />}
      <Toaster />
    </Fragment>
  );
};

export default AppLayout;
