import React from 'react';

import { Totals } from './types';

interface SummaryProps {
  totals: Totals;
}

export function Summary({ totals }: SummaryProps) {
  return (
    <>
      <div>
        <h3 className="mb-2 text-sm font-bold uppercase">Summary</h3>
        <div className="flex justify-between">
          <span className="text-xs uppercase">Subtotal</span>
          <span className="text-xs">{totals.itemsTotalPrice}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-xs uppercase">Shipping</span>
          <span className="text-xs">{totals.shippingPrice}</span>
        </div>
      </div>
      <hr className="border-t border-black/10" />
      <div>
        <div className="flex items-end justify-between">
          <h3 className="text-sm font-bold uppercase">Total</h3>
          <span className="text-lg font-bold">{totals.grandTotalPrice}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-xs uppercase">Including {totals.taxPercent}% tax</span>
          <span className="text-xs">{totals.grandTotalPriceTax}</span>
        </div>
        {totals.totalDiscountPrice && totals.totalDiscountPrice !== 'false' && (
          <div className="flex justify-between">
            <span className="text-xs uppercase">Discount</span>
            <span className="text-xs">{totals.totalDiscountPrice}</span>
          </div>
        )}
      </div>
    </>
  );
}
