import React from 'react';

import { Link } from '@core-frontend/components';
import type { OwnProps } from 'LinkBlock';
import snakeCase from 'lodash/snakeCase';
import { withRouter } from 'next/router';

import { OnDesktopOnly } from '../../layout-components';

import * as Styles from './LinkBlock.module.scss';

export const Component: React.FC<OwnProps & any> = props => {
  const { data, header } = props;

  return (
    <div className={Styles.wrapper}>
      {header && (
        <OnDesktopOnly>
          <h4>{header}</h4>
        </OnDesktopOnly>
      )}

      <ul>
        {data.map(
          (
            link: { external: boolean; url: string; linkText: string; isTextPage: boolean; sys: { id: string } },
            index: number,
          ) => {
            if (link.external) {
              return (
                <li key={index}>
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.linkText}
                  </a>
                </li>
              );
            }

            const pathnameArray = props.location?.pathname?.split('/page') || '';
            const pathname = pathnameArray[pathnameArray.length - 1];
            const hpath = `${link.url?.split('#')[0]}`;
            const hlink = `#${link.url?.split('#')[1]}`;
            const linkToSameSite = pathname === hpath;

            const prefix = link.isTextPage ? '/page' : null;

            return (
              <li key={`${snakeCase(link.linkText)}-${index}`}>
                <Link
                  to={link.isTextPage && linkToSameSite ? hlink : `${prefix || ''}${link.url}`}
                  linkToSameSite={linkToSameSite}
                >
                  {link.linkText}
                </Link>
              </li>
            );
          },
        )}
      </ul>
    </div>
  );
};

export const LinkBlock = withRouter(Component);
