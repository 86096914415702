import TagManager from 'react-gtm-module';

import { priceForCurrentPricelist } from '@utils/prices';
import { ga4htmlMarkup, htmlMarkup } from 'ys-analytics';
import { z } from 'zod';

import { isBrowser } from '../utils/global';

declare global {
  interface Window {
    dataLayer: any[];
    google_tag_manager: any;
  }
}

export const GAtrackOriginalLocation = (): void => {
  if (!isBrowser()) return;
  window.dataLayer = window.dataLayer || [];
  if (!window.dataLayer.find(event => event.event === 'originalLocation')) {
    window.dataLayer.push({
      event: 'originalLocation',
      originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
    });
  }
};

export const GAtrackVirtualPage = (path: string, title: string): void => {
  if (!isBrowser()) return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'virtualPageview',
    pagePath: path,
    pageTitle: title,
  });
};

export const initTagManager = () => {
  if (process.env.NEXT_PUBLIC_GTM_ID && isBrowser() && !window.google_tag_manager) {
    GAtrackOriginalLocation();
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
    ga4htmlMarkup.GA4basicConfig();
  }
};

// GA4
const generateCategoriesCollection = (categories: { name: string[] }[] = []): Record<string, string> =>
  categories?.length > 0 &&
  Object.fromEntries(
    categories.map((value, index) => [index === 0 ? 'item_category' : `item_category${index}`, value.name.join(' ')]),
  );

export const trackGA4ProductList = (supportGaUa = false, listName = null): void => {
  if (isBrowser()) {
    try {
      if (supportGaUa && htmlMarkup) {
        htmlMarkup.eeProductImpressions().catch(console.error);
      }
      if (ga4htmlMarkup) {
        ga4htmlMarkup.trackGa4ProductList(listName).catch(console.error);
      }
    } catch (e) {
      console.log('Error trackGA4ProductList: ', { e });
    }
  }
};

export const trackGA4ProductListAll = (supportGaUa = false): void => {
  if (isBrowser() && htmlMarkup) {
    try {
      if (supportGaUa) {
        htmlMarkup.eeProductImpressions().catch(console.error);
      }
      ga4htmlMarkup.trackGa4ProductListAll();
    } catch (e) {
      console.log('Error trackGA4ProductList: ', { e });
    }
  }
};

export const trackGA4ViewItem = (supportGaUa = false): void => {
  if (isBrowser() && htmlMarkup) {
    try {
      if (supportGaUa) {
        htmlMarkup.eeProductDetailImpressions().catch(console.error);
      }
      ga4htmlMarkup.trackGa4Product().catch(console.error);
    } catch (e) {
      console.log('Error trackGA4ViewItem: ', { e });
    }
  }
};

export const trackGA4ItemClick = (sku: string, product: any, supportGaUa = false): void => {
  if (isBrowser() && htmlMarkup) {
    try {
      if (supportGaUa) {
        htmlMarkup.eeProductClick(sku, product).catch(console.error);
      }
      ga4htmlMarkup.GA4ProductClick(sku, product).catch(console.error);
    } catch (e) {
      console.log('Error trackGA4ViewItem: ', { e });
    }
  }
};

export const getGA4ProductSchema = (product: any, index?: number, item_list_id?: string, item_list_name?: string) => {
  try {
    const productPrice = priceForCurrentPricelist(product);
    const priceBeforeDiscountAsNumberSchema = z.number().safeParse(Number(productPrice?.priceBeforeDiscountAsNumber));
    return JSON.stringify({
      '@type': 'GA4',
      centraID: product.product || product.productId,
      centraProduct: product.centraProduct,
      centraVariant: product.centraVariant,
      currency: `${productPrice?.price?.slice(-3)}`,
      discount: priceBeforeDiscountAsNumberSchema.success
        ? String(priceBeforeDiscountAsNumberSchema.data - productPrice?.priceAsNumber)
        : '',
      image: product?.media?.standard || product?.media,
      ...(index !== undefined && { index }),
      fullPrice: productPrice?.priceBeforeDiscountAsNumber,
      item_brand: product.brandName,
      item_id: product.sku,
      ...(item_list_id !== undefined && { item_list_id }),
      ...(item_list_name !== undefined && { item_list_name }),
      item_name: product.name,
      ...generateCategoriesCollection(product?.categories),
      item_variant: product.variantName,
      price: productPrice?.priceAsNumber,
      quantity: product.quantity,
      ...(product.uri && { url: `${product.uri.startsWith('/p/') ? '' : '/p/'}${product.uri}` }),
    });
  } catch (error) {
    console.log('Schema GA error', error);
  }
};

// old UA
export type AddedTagsType = {
  scriptTags: string[];
};

export const getCategoryAnalytics = () => {
  if (isBrowser() && htmlMarkup) {
    try {
      htmlMarkup.eeProductImpressions().catch(console.error);
    } catch (e) {
      console.log('Error: ', { e });
    }
  }
};

export const getProductClickAnalytics = (sku: string): void => {
  // use as manual call or check the auto event from ys??
  if (isBrowser() && htmlMarkup) {
    try {
      htmlMarkup.eeProductClickAll(sku).catch(console.error);
    } catch (e) {
      console.log('Error: ', { e });
    }
  }
};

export const getProductAnalytics = (): void => {
  if (isBrowser() && htmlMarkup) {
    try {
      htmlMarkup.eeProductDetailImpressions().catch(console.error);
    } catch (e) {
      console.log('Error: ', { e });
    }
  }
};
