import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { gql, useMutation, useQuery } from '@apollo/client';
import { AlertBar, Block } from '@components/components';
import { OnBigDesktopOnly } from '@components/layout-components';
import { RichText } from '@components/renderers/ContentfulPagesRenderer/components';
import cx from 'clsx';

import { ArrowRight } from '../../../public/images/icons';

import { contactFormQuery } from './contactFormQuery';

import Styles from './ContactForm.module.scss';

type Value = {
  firstName: string;
  email: string;
  phone: string;
  message: string;
};

type OwnProps = {
  mediaSlug?: string;
};

const CONTACT_FORM_MUTATION = gql`
  mutation CONTACT_FORM_MUTATION($message: String!, $kind: String!, $subject: String!) {
    postContactForm(message: $message, subject: $subject, kind: $kind) {
      message
    }
  }
`;

export const ContactForm: React.FC<OwnProps> = ({ mediaSlug = 'mediaForContactForm' }) => {
  const [consent, setConsent] = useState(false);
  const [send, { data }] = useMutation(CONTACT_FORM_MUTATION);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (e: Value) => {
    send({
      variables: {
        message: `${e.firstName}
      ${e.message}
      ${e.email} - ${e.phone}
      `,
        kind: 'contact',
        subject: 'Customer message',
      },
    }).then();
  };

  const { data: galleriesData } = useQuery(contactFormQuery, {
    variables: {
      mediaSlug,
    },
  });

  const desktopImage = galleriesData?.cfMedias[0].desktopImage.file.url;
  const contactFormContent = galleriesData?.contactFormContent[0] || [];

  const contactFormFailed = galleriesData?.contactFormFailed[0] || [];
  const contactFormSuccess = galleriesData?.contactFormSuccess[0] || [];

  const header = contactFormContent?.headerText;
  const content = contactFormContent?.content;
  const contentText = content && JSON.parse(content);
  const consentText = contactFormContent?.consent;
  const consentParsedText = consentText && JSON.parse(consentText);

  const { formatMessage } = useIntl();

  const toggleConsent = () => {
    setConsent(consent => !consent);
  };

  return (
    <Block width="fullscreen" theme="dark" id="contactForm">
      <div className={Styles.wrapper}>
        {data?.postContactForm?.message && (
          <>
            {data.postContactForm.message === 'message sent' ? (
              <AlertBar warning={false} messageData={contactFormSuccess} />
            ) : (
              <AlertBar warning={true} messageData={contactFormFailed} />
            )}
          </>
        )}
        <OnBigDesktopOnly>
          <div className={Styles.photo}>
            <img src={desktopImage} />
          </div>
        </OnBigDesktopOnly>
        <form onSubmit={handleSubmit(onSubmit)} className={Styles.form}>
          <h4 className={Styles.header}>{header}</h4>
          {contentText && <div className={Styles.richContent}>{RichText(contentText)}</div>}
          <input
            id="contact-name"
            {...register('firstName', { required: 'This field is required' })}
            className={Styles.input}
            placeholder={formatMessage({ id: 'input.name' })}
          ></input>
          <p className={Styles.message}>
            {errors.firstName && typeof errors.firstName.message === 'string' && errors.firstName.message}
          </p>
          <input
            id="contact-email"
            className={Styles.input}
            type="email"
            {...register('email', {
              required: 'This field is required',
              pattern: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i,
            })}
            placeholder={formatMessage({ id: 'input.placeholder' })}
          ></input>
          <p className={Styles.message}>
            {errors.email && typeof errors.email.message === 'string' && errors.email.message}
          </p>
          <input
            id="contact-phone"
            type="tel"
            className={Styles.input}
            {...register('phone', { required: 'This field is required' })}
            placeholder={formatMessage({ id: 'input.phoneNumber' })}
          ></input>
          <p className={Styles.message}>
            {errors.phone && typeof errors.phone.message === 'string' && errors.phone.message}
          </p>
          <textarea
            className={Styles.textarea}
            id="contact-message"
            {...register('message', { required: 'This field is required' })}
            placeholder={formatMessage({ id: 'input.message' })}
          />
          <p className={Styles.message}>
            {errors.message && typeof errors.message.message === 'string' && errors.message.message}
          </p>
          <div className={Styles.consent}>
            <input
              id="newsletter-chexbox"
              className={consent ? cx(Styles.checkbox, Styles.active) : Styles.checkbox}
              onClick={toggleConsent}
              type="checkbox"
              {...register('consent', { required: 'This field is required' })}
            />
            {consentParsedText && <p>{RichText(consentParsedText)}</p>}
          </div>
          <p className={Styles.consentMessage}>
            {errors.consent && typeof errors.consent.message === 'string' && errors.consent.message}
          </p>
          <button
            type="submit"
            aria-label="submit"
            value={formatMessage({ id: 'input.submit' })}
            className={Styles.submitButton}
          >
            <ArrowRight width={'30px'} height={'30px'} />
          </button>
        </form>
      </div>
    </Block>
  );
};
