import React from 'react';

import { Block, ImageBanner } from '@components/components';
import { Link } from '@core-frontend/components';
import cx from 'clsx';

import type { Media } from '../../../../../types';
import type { RichContent } from '../../../../../types/contentful/RichContent';

import { Promo50SmallMediaContent } from './components/Promo50SmallMediaContent/Promo50SmallMediaContent';

import Styles from './Promo50Small.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    imageLeft: Media;
    imageRight: Media;
    richContentLeft?: RichContent | null;
    richContentRight?: RichContent | null;
    width?: 'fullscreen' | 'container';
    theme?: 'light' | 'dark';
    bigImagePosition?: 'left' | 'right';
  };
  priority?: boolean;
};

export const Promo50Small: React.FC<OwnProps> = ({ module, priority }) => {
  if (!module) {
    return null;
  }

  const { imageLeft, imageRight, richContentLeft, richContentRight, width, theme, bigImagePosition, slug } = module;
  const { links: linksLeft } = richContentLeft || {};
  const linkUrlLeft = linksLeft && linksLeft[0].url;
  const { links: linksRight } = richContentRight || {};
  const linkUrlRight = linksRight && linksRight[0].url;

  const renderMedia = (media: Media, content: RichContent, big: boolean, links?: string) => {
    const { desktopImage, mobileImage } = media || {};

    const containerClassNames = cx({
      [Styles.container]: true,
      ...(content && 'contentTheme' in content && { [Styles[content.contentTheme]]: true }),
    });

    const innerClassNames = cx({
      [Styles.inner]: true,
      [Styles.big]: big,
      [Styles.small]: !big,
    });

    return (
      <div className={containerClassNames}>
        {links ? (
          <Link to={links}>
            {(desktopImage || mobileImage) && (
              <div className={innerClassNames}>
                <ImageBanner priority={priority} media={media} />
              </div>
            )}
            {content && (
              <div className={Styles.content}>
                <Promo50SmallMediaContent className={Styles.contentPadding} config={content} />
              </div>
            )}
          </Link>
        ) : (
          <>
            {(desktopImage || mobileImage) && (
              <div className={innerClassNames}>
                <ImageBanner priority={priority} media={media} />
              </div>
            )}
            {content && (
              <div className={Styles.content}>
                <Promo50SmallMediaContent className={Styles.contentPadding} config={content} />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <Block width={width} theme={theme} id={slug}>
      <div className={Styles.wrapper}>
        {renderMedia(imageLeft, richContentLeft, bigImagePosition === 'left', linkUrlLeft)}
        {renderMedia(imageRight, richContentRight, bigImagePosition !== 'left', linkUrlRight)}
      </div>
    </Block>
  );
};

export default Promo50Small;
