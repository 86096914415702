export const updateCountry = (country: string, mutation: (arg: any) => any) => {
  mutation({
    variables: {
      country,
    },
  }).then(mutationResponse => {
    const { changeCountry } = mutationResponse?.data;
    const selection = JSON.parse(changeCountry.selection);
    const checkoutPayload = {
      market: changeCountry.location.market,
      shippingMethod: selection.shippingMethod,
      paymentMethod: selection.paymentMethod,
      location: changeCountry.location,
      selection,
      formName: 'shippingAddress',
      paymentMethods: JSON.parse(changeCountry.paymentMethods),
      shippingMethods: JSON.parse(changeCountry.shippingMethods),
      paymentFields: JSON.parse(changeCountry.paymentFields),
      countries: JSON.parse(changeCountry.countries),
    };
    const payload = checkoutPayload;

    if (window.store) {
      window.store.dispatch({
        type: 'gc/COUNTRY_CHANGE_SUCCESS',
        payload,
      });
      window.location.reload();
    }
  });
};
