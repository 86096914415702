import { gql } from '@apollo/client';

export const SimpleContentFragment = gql`
  fragment SimpleContentFragment on CfSimpleContent {
    title
    slug
    headerText
    content
  }
`;
