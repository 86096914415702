import React from 'react';

import type { OwnProps } from 'RichTextParagraph';

import Styles from './RichTextParagraph.module.scss';

export const RichTextParagraph: React.FC<React.PropsWithChildren<OwnProps>> = ({ children }) => {
  if (!children) {
    return null;
  }

  return <p className={Styles.paragraph}>{children}</p>;
};
