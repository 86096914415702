import React, { useMemo } from 'react';

import { priceForCurrentPricelist } from '@utils/prices';
import cx from 'clsx';

import type { Product } from '../../../types/centra-api';

import * as Styles from './ProductPrice.module.scss';

type ProductPriceProps = {
  grid?: boolean;
  product: Product;
};

export const ProductPrice: React.FC<ProductPriceProps> = ({ product, grid }) => {
  const { showAsOnSale } = product;
  const classNames = cx(Styles.wrapper, {
    [Styles.discount]: showAsOnSale,
    [Styles.grid]: grid,
  });

  const productPrice = useMemo(() => priceForCurrentPricelist(product), [product]);

  return (
    <span className={classNames} itemProp="offers" itemType="http://schema.org/Offer">
      <span className={Styles.current} itemProp="price">
        {productPrice?.price}
      </span>
      {showAsOnSale && <del className={Styles.original}>{productPrice?.priceBeforeDiscount}</del>}
    </span>
  );
};
