import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import type { BreadcrumbLink } from '../Breadcrumbs/Breadcrumbs';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

import Styles from './CategoryBreadcrumbs.module.scss';

type OwnProps = {
  color?: 'white' | 'black';
};

export const CategoryBreadcrumbs: React.FC<OwnProps> = ({ color }) => {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState<null | BreadcrumbLink[]>(null);

  useEffect(() => {
    if (router) {
      const linkPath = router.asPath.split('/');
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => {
        const [splittedPath] = path.split('?');
        return { breadcrumb: splittedPath, href: '/' + linkPath.slice(0, i + 1).join('/') };
      });

      setBreadcrumbs(pathArray);
    }
  }, [router]);

  return (
    <div className={Styles.container}>
      <Breadcrumbs first="Home" breadcrumbs={breadcrumbs} {...(color && { color })} />
    </div>
  );
};
