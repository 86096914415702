import type { MouseEvent } from 'react';
import React, { forwardRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import cx from 'clsx';

import type { FilterTypes } from '../TileMenu/TileMenu';

import type { OwnProps } from './types';

import Styles from './Filters.module.scss';

// eslint-disable-next-line react/display-name
export const Filters: React.FC<JSX.IntrinsicElements['div'] & OwnProps> = forwardRef((props, ref) => {
  const { opened, onFilterChange, onReset, onApply, filters, isSearchPage, ...rest } = props;
  const [expander, setExpander] = useState<number | null>(null);

  if (!opened) {
    return null;
  }

  const handleClick = (index: number) => (event: MouseEvent<HTMLDivElement>) => {
    if (expander === index) {
      setExpander(null);
    } else {
      setExpander(index);
    }
  };

  return (
    <>
      <TransitionGroup>
        <CSSTransition timeout={100} classNames="fade" appear={true}>
          <div
            className={cx(Styles.wrapper, {
              [Styles.searchFilter]: isSearchPage,
            })}
            {...rest}
            ref={ref}
          >
            <div className={Styles.content}>
              <div className={Styles.container}>
                {Object.keys(filters)
                  .filter(filter => filter !== 'types')
                  .map((filter: FilterTypes, index) => {
                    const { name, items, type, selected, definedColors, defaultFilter } = filters[filter];
                    if (!items || items.length === 0) {
                      return null;
                    }

                    const labelClassNames = cx({
                      [Styles.label]: true,
                      [Styles.expanded]: expander === index,
                    });

                    return (
                      <div key={`filter-column-${filter}`} id={`filter-${filter}`} className={Styles.column}>
                        <div className={labelClassNames} onClick={handleClick(index)}>
                          {name}
                        </div>
                        <ul className={Styles.list}>
                          {items.map((item: string, itemIndex) => {
                            const finalItem = item.replaceAll(' ', '-').toLowerCase();
                            const itemClassNames = cx({
                              [Styles.item]: true,
                              [Styles[type]]: true,
                              [Styles.selected]:
                                selected.includes(finalItem) ||
                                (defaultFilter && selected.length === 0 && defaultFilter === finalItem),
                            });
                            return (
                              <li
                                key={`filter-column-${filter}-${itemIndex}`}
                                className={itemClassNames}
                                onClick={onFilterChange(filter, finalItem)}
                                {...(type === 'color' &&
                                  definedColors &&
                                  definedColors[finalItem] && {
                                    'data-tooltip': item.replaceAll('-', ' '),
                                    style: { backgroundColor: definedColors[finalItem] },
                                  })}
                              >
                                {type !== 'color' && item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
              </div>
              <div className="mx-4 mb-4 flex justify-end gap-2">
                <button onClick={onReset} className={Styles.reset}>
                  Reset filter
                </button>
                <button onClick={() => onApply()} className={Styles.apply}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
});
