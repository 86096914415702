import React, { Fragment } from 'react';

import { Block, ImageBanner } from '@components/components';
import { Link } from '@core-frontend/components';
import cx from 'clsx';

import type { Media } from '../../../../../types';
import type { RichContent } from '../../../../../types/contentful/RichContent';

import { Promo50MediaContent } from './components/Promo50MediaContent/Promo50MediaContent';

import Styles from './Promo50.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    image: Media;
    richContent?: RichContent | null;
    width?: 'fullscreen' | 'container';
    theme?: 'light' | 'dark';
    parameter?: string;
    imagePosition?: 'left' | 'right';
  };
  priority?: boolean;
};

export const Promo50: React.FC<OwnProps> = ({ module, priority }) => {
  if (!module) {
    return null;
  }

  const { image, theme, width, parameter, richContent, imagePosition, slug } = module;
  const { links } = richContent || {};
  const linkUrl = links && links[0]?.url;
  const linkText = links && links[0]?.title;

  const { desktopImage, mobileImage } = image || {};

  const containerClassNames = cx({
    [Styles.container]: true,
  });

  const renderContent = () => (
    <div className={Styles.wrapper}>
      {imagePosition === 'left' ? (
        <Fragment>
          {(desktopImage || mobileImage) && (
            <div className={containerClassNames}>
              <ImageBanner priority={priority} className={Styles.image} media={image} />
            </div>
          )}
          {richContent && (
            <div className={Styles.content}>
              <Promo50MediaContent
                className={Styles.contentPadding}
                config={richContent}
                numberAttribute={parameter}
                imagePosition={imagePosition}
              />
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {richContent && (
            <div className={Styles.content}>
              <Promo50MediaContent
                className={Styles.contentPadding}
                config={richContent}
                numberAttribute={parameter}
                imagePosition={imagePosition}
              />
            </div>
          )}
          {(desktopImage || mobileImage) && (
            <div className={containerClassNames}>
              <ImageBanner priority={priority} className={Styles.image} media={image} />
            </div>
          )}
        </Fragment>
      )}
    </div>
  );

  return (
    <Block width={width} theme={theme} id={slug}>
      {linkUrl ? (
        <Link className={Styles.containerLink} to={linkUrl} {...(linkText && { 'aria-label': linkText })}>
          {renderContent()}
        </Link>
      ) : (
        renderContent()
      )}
    </Block>
  );
};

export default Promo50;
