import React, { Fragment } from 'react';

import { Block } from '@components/components';
import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import cx from 'clsx';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { RichContent } from '../../../../../types/contentful/RichContent';
import type { SimpleContent } from '../../../../../types/contentful/SimpleContent';
import { RichText } from '../RichText';

import Styles from './TextBar.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    simpleContents?: RichContent[];
    width?: 'fullscreen' | 'container';
  };
};

SwiperCore.use([Pagination]);

export const TextBar: React.FC<OwnProps> = ({ module }) => {
  if (!module) {
    return null;
  }

  const { simpleContents, width, slug } = module;

  const mobileConfig = {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    noSwiping: true,
    ...(simpleContents.length >= 2 && {
      noSwiping: false,
      pagination: {
        el: Styles.pagination,
        clickable: true,
      },
    }),
  };

  const renderRichContent = (richContent?: SimpleContent) => {
    const { content, headerText } = richContent;
    const contentText = content && JSON.parse(content);

    const containerClassNames = cx({
      [Styles.container]: true,
    });

    return (
      <div className={containerClassNames}>
        <div className={Styles.content}>
          <h4 className={Styles.title}>{headerText}</h4>
          <div className={Styles.description}>{RichText(contentText)}</div>
        </div>
      </div>
    );
  };

  return (
    <Block width={width} theme="light" id={slug}>
      <div className={Styles.wrapper}>
        <OnMobileAndTabletOnly>
          <Swiper {...mobileConfig}>
            {simpleContents.map((richContent, index) => (
              <SwiperSlide key={`text-bar-mobile-slide-${index}`}>{renderRichContent(richContent)}</SwiperSlide>
            ))}
          </Swiper>
        </OnMobileAndTabletOnly>
        <OnDesktopOnly>
          {simpleContents.map((richContent, index) => (
            <Fragment key={`text-bar-desktop-item-${index}`}>{renderRichContent(richContent)}</Fragment>
          ))}
        </OnDesktopOnly>
      </div>
    </Block>
  );
};

export default TextBar;
