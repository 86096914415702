import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface TopPromoBannerStore {
  lastContent: string;
  close: (lastContent: string) => void;
}

export const useTopPromoBannerStore = create(
  persist<TopPromoBannerStore>(
    set => ({
      lastContent: '',
      close: lastContent => set({ lastContent }),
    }),
    {
      name: 'topPromoBannerStore',
      getStorage: () => localStorage,
    },
  ),
);
