import { gql } from '@apollo/client';
import { ContactFormFragment } from '@components/fragments/ContactFormFragment';
import { MessageBarFragment } from '@components/fragments/MessageBarFragment';

export const contactFormQuery = gql`
  query contactForm($mediaSlug: String!) {
    contactFormContent: cfContactForms(q: { fields: { slug: "contact-form" } }) {
      ...ContactFormFragment
    }
    contactFormFailed: cfMessageBars(q: { fields: { slug: "contactFormFailed" } }) {
      ...MessageBarFragment
    }
    contactFormSuccess: cfMessageBars(q: { fields: { slug: "contactFormSuccess" } }) {
      ...MessageBarFragment
    }
    cfMedias(q: { fields: { slug: $mediaSlug } }) {
      slug
      desktopImage {
        title
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      mobileImage {
        title
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
    }
  }
  ${ContactFormFragment}
  ${MessageBarFragment}
`;
