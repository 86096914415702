import type { CategoryData, CustomElementData, MenuElement, PageType } from './NavigationBuilderTypes';

export const findCustomElement = (category: string, customData: CustomElementData[]): CustomElementData | undefined => {
  return customData.find(customElement => {
    return customElement.id === category;
  });
};

export const getElementName = (element: MenuElement, customData: CustomElementData[], categoryData: any) => {
  if (element.type === 'category' && categoryData && categoryData.categories) {
    return categoryData.categories.map((category: CategoryData) => {
      if (category.category === element.category) {
        return category.name[category.name.length - 1];
      }
    });
  }
  if (element.type === 'customLink' && element.category && customData) {
    const myElement = findCustomElement(element.category, customData);
    return myElement ? myElement.name : '';
  }
};

export const getElementLink = (element: MenuElement, customData: CustomElementData[], categoryData: any) => {
  if (element.type === 'page') {
    const currentPage = categoryData.cfPages.find((pageData: PageType) => pageData.slug === element.category);

    if (currentPage) {
      return `/page/${currentPage.slug}`;
    }
  }

  if (element.type === 'category' && categoryData && categoryData.categories) {
    const currentCategory = categoryData.categories.find(
      (category: CategoryData) => category.category === element.category,
    );

    if (currentCategory) {
      return `/${currentCategory.uri}`;
    }
  }

  if (element.type === 'customLink' && element.category && customData) {
    const myElement = findCustomElement(element.category, customData);
    return myElement ? myElement.uri : '';
  }

  return '';
};
