import React from 'react';

import { CurrencySelector } from '@components/components';

import Styles from './NavigationCurrencySelector.module.scss';

export const NavigationCurrencySelector: React.FC = () => {
  return (
    <CurrencySelector>
      {({ renderCountryList }) => {
        return (
          <div className={Styles.wrapper}>
            <div className={Styles.container}>{renderCountryList('name')}</div>
          </div>
        );
      }}
    </CurrencySelector>
  );
};
