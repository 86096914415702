import React from 'react';

import * as Styles from './RichBlockquote.module.scss';

export const RichBlockquote: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  if (!children) {
    return null;
  }

  return <blockquote className={Styles.blockquote}>{children}</blockquote>;
};
