import React, { useEffect, useState } from 'react';

import type { OwnProps } from 'AccordionsGroup';
import cx from 'clsx';

import * as Styles from './AccordionsGroup.module.scss';

export const AccordionsGroup: React.FC<React.PropsWithChildren<OwnProps>> = ({
  freezeDefault,
  allowMultipleOpen = false,
  children,
  className,
  id,
  version = 'horizontal',
}) => {
  const [expandedSections, setExpandedSections] = useState<{ [key: number]: boolean }>({});
  useEffect(() => {
    React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        const isChildExpanded = child.props.expanded;

        if (!freezeDefault && child.props.expanded !== undefined) {
          setExpandedSections(prevState => ({
            ...prevState,
            [index]: isChildExpanded,
          }));
        }
      }
    });
  }, [children, freezeDefault]);

  const setExpanded = (index: number, expanded: boolean) => {
    if (allowMultipleOpen) {
      setExpandedSections(prevState => ({
        ...prevState,
        [index]: expanded,
      }));
    } else {
      setExpandedSections({ [index]: expanded });
    }
  };

  const handleToggle = (index: number) => {
    const expanded = expandedSections[index];
    setExpanded(index, !expanded);
  };

  const wrapperClasses = cx(Styles.wrapper, {
    [className]: true,
    [Styles[version]]: version,
  });

  return (
    <div className={wrapperClasses} id={id}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          const newProps = {
            ...child.props,
            key: index,
            onToggle: () => {
              handleToggle(index);
            },
            expanded: expandedSections[index],
            version: version,
          };

          return React.cloneElement(child, { ...newProps });
        }

        return null;
      })}
    </div>
  );
};
