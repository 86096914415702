import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getProductClickAnalytics } from '@core-analytics/getAnalyticsFunctions';
import { Link } from '@core-frontend/components';
import { isInStock } from '@utils/isInStock';
import cx from 'clsx';

import { ProductItemImage } from '../ProductItemImage';
import { ProductItemInfo } from '../ProductItemInfo';

import type { OwnProps } from './types';

import Styles from './ProductItem.module.scss';

export const ProductItem: React.FC<OwnProps & any> = ({
  product,
  imageType,
  noHoverAction,
  placeholder,
  mockedImage,
  className,
  textColor,
  selectedProduct,
  height,
  width,
  productItemClass,
}) => {
  const { name, uri, media, brandName, variantName, sku, items, showAsOnSale, categoryUri } = product;
  const imageAltText = `${brandName} ${name} - ${variantName}`;
  const productUri = categoryUri ? `/${categoryUri}/${uri}` : `/${uri}`;
  const [hovered, setHovered] = React.useState(false);

  const commingSoon = false;

  const classNames = cx(
    Styles.item,
    className,
    {
      ...(selectedProduct !== undefined && { [Styles.inactive]: !selectedProduct }),
    },
    productItemClass,
  );

  // eslint-disable-next-line no-restricted-globals
  const flipBackFirstSide = () => {
    if (noHoverAction) {
      return;
    }
    setHovered(false);
  };

  const flipBackSecondSide = () => {
    if (noHoverAction) {
      return;
    }
    setHovered(true);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-testid="product-item"
      className={classNames}
      onMouseOver={flipBackSecondSide}
      onMouseLeave={flipBackFirstSide}
      onFocus={() => {}}
      itemProp="itemListElement"
      itemType="http://schema.org/ListItem"
      data-url={uri}
      data-sku={sku}
    >
      <Link
        className={Styles.link}
        to={`/product${productUri}`}
        itemProp="url"
        onClick={() => {
          getProductClickAnalytics(sku);
        }}
        data-analytics-product={sku}
      >
        <ProductItemImage
          uri={uri}
          sku={sku}
          media={media}
          alt={imageAltText}
          hovered={hovered}
          placeholder={placeholder}
          mockedImage={mockedImage}
          imageType={imageType}
          multigridRatio={{ height, width }}
        />
        <ProductItemInfo product={product} textColor={textColor} />
        {!isInStock(items) && (
          <span className={Styles.soldOut}>
            <FormattedMessage id="outofstock.label" />
          </span>
        )}
        {showAsOnSale && isInStock(items) && (
          <span className={Styles.sale}>
            <FormattedMessage id="product.sale" />
          </span>
        )}
        {commingSoon && (
          <span className={Styles.soldOut}>
            <FormattedMessage id="product.commingSoon" />
          </span>
        )}
      </Link>
    </div>
  );
};
