import React from 'react';

import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import cx from 'clsx';
import type { OwnProps } from 'Logo';
import Link from 'next/link';

import { Logo as LogoIcon } from '../../../public/images/icons';

import Styles from './Logo.module.scss';

export const Logo: React.FC<OwnProps> = ({ sizes, scrolled, className }) => {
  const wrapperClassesList = cx(Styles.wrapper, className, {
    [Styles.scrolled]: scrolled,
  });

  const onLogoClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Link href="/" locale={false} legacyBehavior>
      <a className={wrapperClassesList} onClick={onLogoClick} data-testid="logo-link">
        <OnDesktopOnly>
          <LogoIcon
            aria-label="BLK DNM Logo"
            height={scrolled ? sizes.height.desktop.scrolled : sizes.height.desktop.standard}
            width={scrolled ? sizes.width.desktop.scrolled : sizes.width.desktop.standard}
          />
        </OnDesktopOnly>
        <OnMobileAndTabletOnly>
          <LogoIcon
            aria-label="BLK DNM Logo"
            height={scrolled ? sizes.height.mobile.scrolled : sizes.height.mobile.standard}
            width={scrolled ? sizes.width.mobile.scrolled : sizes.width.mobile.standard}
          />
        </OnMobileAndTabletOnly>
      </a>
    </Link>
  );
};
