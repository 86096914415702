type MediaFile = {
  title: string;
  file: {
    url: string;
    contentType: string;
    details: {
      image: {
        width: number;
        height: number;
      };
    };
  };
};
type Link = {
  title: string;
  url: string;
  linkText: string;
  external?: boolean;
  isTextPage?: boolean;
  textColor?: string;
  linkPosition?: string;
};
type Media =
  | {
      title: string;
      slug?: string;
      desktopImage: MediaFile;
      mobileImage: MediaFile;
      iframe: string;
      video: MediaFile;
      links: Link[];
    }
  | any;
type Product = {
  sku: string;
  uri: string;
  product: string;
  name: string;
  media: Media;
};

export type Products = {
  marketRestriction: boolean;
  items: Record<string, Product[]>;
};

export const buildProductsList = (products: Products, currentMarket: string) => {
  if (products.marketRestriction && currentMarket) {
    const hasProductItemsForCurrentMarket =
      products.items && products.items[currentMarket] && products.items[currentMarket].length > 0;

    if (!hasProductItemsForCurrentMarket) {
      // eslint-disable-next-line no-console
      console.warn('[CentraProducts] - No products for specific market:', currentMarket);

      return [];
    }

    return products.items[currentMarket];
  }

  return products.items['all'];
};
