import type React from 'react';
import type { ReactElement } from 'react';

import { useQuery } from '@apollo/client';

import type { Media } from '../../../../../types';

import type { CategoryData, CustomElementData, MenuElement, PageType } from './NavigationBuilderTypes';
import { navigationBuilderQuery } from './NavigationQueries';

interface OwnProps {
  slug: string;
  children: (data: {
    categoryTreeItems: MenuElement[];
    customItems: CustomElementData[];
    categories: CategoryData[];
    cfPages: PageType[];
    navigationMedia?: Media[];
  }) => ReactElement;
}

export const NavigationBuilder: React.FC<OwnProps> = ({ slug, children }) => {
  const navigationBuilderVariables = {
    query: {
      fields: {
        slug,
      },
    },
  };

  const { data: navigationData } = useQuery(navigationBuilderQuery, { variables: navigationBuilderVariables });

  if (!navigationData || !navigationData.cfNavigationBuilders[0]) {
    return null;
  }

  const navigationModel = navigationData && JSON.parse(navigationData.cfNavigationBuilders[0]?.navigationBuilder);
  const { categoryTreeItems, customItems } = navigationModel;

  const navigationMedia = navigationData.cfNavigationBuilders[0].navigationMedia || [];

  return children({
    categoryTreeItems,
    customItems,
    categories: navigationData.categories,
    cfPages: navigationData.cfPages,
    navigationMedia,
  });
};
