import Image from 'next/image';

import AmericanExpress from './logo-american-express.svg';
import Klarna from './logo-klarna.svg';
import Mastercard from './logo-mastercard.svg';
import Visa from './logo-visa.svg';

const ENABLE_KLARNA = false;

const TrustLogos: React.FC = () => (
  <section className="flex gap-2" data-testid="trust-logos">
    <Image alt="Mastercard logo" src={Mastercard} />
    <Image alt="Visa logo" src={Visa} />
    {ENABLE_KLARNA && <Image alt="Klarna logo" src={Klarna} />}
    <Image alt="American Express logo" src={AmericanExpress} />
  </section>
);

export { TrustLogos };
