import React from 'react';

import { ProductItem } from './ProductItem';
import { Item } from './types';

interface ProductsProps {
  products: Item[];
}

export function Products({ products }: ProductsProps) {
  return (
    <ul className="mt-4 grid gap-8">
      {products.map(item => (
        <ProductItem key={item.item} product={item} />
      ))}
    </ul>
  );
}
