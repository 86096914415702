import { gql } from '@apollo/client';

export const UPDATE_COUNTRY_MUTATION = gql`
  mutation UpdateCountryMutation($country: String!) {
    changeCountry(country: $country) {
      selection
      paymentMethods
      shippingMethods
      paymentFields
      countries
      location {
        country
        market
        name
        state
        stateName
        eu
        shipTo
        language
      }
    }
  }
`;

export const countryQuery = gql`
  query countries {
    countries {
      country
      name
      eu
      language
      currency
    }
  }
`;
