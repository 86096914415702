import { useMutation, useQuery } from '@apollo/client';
import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import { cn } from '@components/ui/utils';
import { Loader } from '@core-frontend/components';
import { isBrowser } from '@core-utils/global';

import { TrustLogos } from '../TrustLogos';
import { Footer } from '..';

import { DesktopFooter } from './DesktopFooter/DesktopFooter';
import { MobileFooter } from './MobileFooter/MobileFooter';
import { mutation, pageFooterQuery } from './PageFooterQuery';

declare global {
  interface Window {
    CookieConsent: any;
  }
}

const renewConsent = () => isBrowser() && window.CookieConsent?.renew?.();

export const PageFooter: React.FC = () => {
  const [newsletterSubscription] = useMutation(mutation);
  const { loading, error, data } = useQuery(pageFooterQuery);

  const cookieConsentLabel = 'cookie consent';

  const renderCookieModalButton = () => {
    if (!isBrowser()) {
      return null;
    }

    return (
      <button key="cookie-modal-button" type="button" onClick={renewConsent}>
        {cookieConsentLabel}
      </button>
    );
  };

  if (!data) {
    return null;
  }

  const menuElements = data.cfMenus[0]?.menu || [];
  const newsletterMedias = data.cfMedias[0] || [];
  const newsletterContent = data.newsletterContent[0] || [];

  if (!menuElements) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Error :/</p>;
  }

  return (
    <Footer
      className={cn('mb-6 mt-auto flex w-full max-w-screen-xl flex-col items-center justify-center self-center')}
      data-testid="page-footer"
    >
      <OnDesktopOnly>
        <DesktopFooter
          menu={menuElements}
          newsletterContent={newsletterContent}
          newsletterMedias={newsletterMedias}
          newsletterSubscription={newsletterSubscription}
          renderCookieModalButton={renderCookieModalButton}
        />
      </OnDesktopOnly>
      <OnMobileAndTabletOnly>
        <MobileFooter
          menu={menuElements}
          newsletterContent={newsletterContent}
          newsletterMedias={newsletterMedias}
          newsletterSubscription={newsletterSubscription}
          renderCookieModalButton={renderCookieModalButton}
        />
      </OnMobileAndTabletOnly>
      <TrustLogos />
    </Footer>
  );
};

export default PageFooter;
