import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import type { BreadcrumbLink } from '../Breadcrumbs/Breadcrumbs';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

import Styles from './EditorialPageBreadcrumbs.module.scss';

type OwnProps = {
  linkedPages?: any;
  color?: 'white' | 'black';
};

export const EditorialPageBreadcrumbs: React.FC<OwnProps> = ({ linkedPages, color }) => {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState<null | BreadcrumbLink[]>(null);

  const getBreadcrumbTitle = (linkPath: string[], path: string) => {
    if (!linkPath?.length || !linkedPages) {
      return path;
    }
    const [, ...linkPathRest] = linkPath;
    const formattedLinkPath = linkPathRest.join('/');
    const picked = linkedPages.filter(path => path.slug === formattedLinkPath);
    return picked?.pageTitle || picked?.title || path;
  };

  useEffect(() => {
    if (router) {
      const linkPath = router.asPath.split('/');
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => {
        return { breadcrumb: getBreadcrumbTitle(linkPath, path), href: '/' + linkPath.slice(0, i + 1).join('/') };
      });

      setBreadcrumbs(pathArray);
    }
  }, [router]);

  return (
    <div className={Styles.container}>
      <Breadcrumbs first="HOME" breadcrumbs={breadcrumbs} skip="page" {...(color && { color })} />
    </div>
  );
};
