import React from 'react';

import cx from 'clsx';

import type { OwnProps } from './types';

import Styles from './StickyHeader.module.scss';

export const StickyHeader: React.FC<React.PropsWithChildren<OwnProps>> = ({ children, className }) => {
  const headerWrapperStyles = cx(Styles.wrapper, className);

  return (
    <div data-testid="header-wrapper" className={headerWrapperStyles}>
      {children}
    </div>
  );
};
