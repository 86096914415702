import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { SimpleContentFragment } from '@components/fragments/SimpleContentFragment';
import { RichText } from '@components/renderers/ContentfulPagesRenderer/components/RichText/RichText';
import { XIcon } from 'lucide-react';
import { useTopPromoBannerStore } from 'store/topPromoBannerStore';

export const TOP_PROMO_BANNER_QUERY = gql`
  query topPromoBanner {
    topPromoBanner: cfSimpleContents(q: { fields: { slug: "top-promo-banner" } }) {
      ...SimpleContentFragment
    }
  }
  ${SimpleContentFragment}
`;

export function TopPromoBanner() {
  const { data } = useQuery(TOP_PROMO_BANNER_QUERY);
  const { close, lastContent } = useTopPromoBannerStore();

  const topPromoBanner = data?.topPromoBanner?.[0];

  if (!topPromoBanner || !topPromoBanner.content || lastContent === topPromoBanner.content) {
    return null;
  }

  let promoContent: string;

  try {
    promoContent = JSON.parse(topPromoBanner.content);
  } catch (e) {
    console.error('Error parsing top promo banner content', e);
    return null;
  }

  return (
    <div className="border-b border-gray-100">
      <div className="container py-4 text-center text-sm text-gray-600">
        <div className="flex">
          {RichText(promoContent)}
          <button
            onClick={() => close(topPromoBanner.content)}
            className="ml-auto text-black focus:outline-none"
            type="button"
          >
            <XIcon strokeWidth={1} />
          </button>
        </div>
      </div>
    </div>
  );
}
