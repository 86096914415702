import React from 'react';

import { cn } from '@components/ui/utils';
import type { OwnProps } from 'Footer';

export const Footer: React.FC<React.PropsWithChildren<OwnProps>> = ({ className, children }) => (
  <footer
    className={cn('mt-auto flex w-full max-w-screen-xl flex-col items-center justify-center self-center', className)}
  >
    {children}
  </footer>
);
