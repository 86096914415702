import React, { Fragment } from 'react';

import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';

import type { Media } from '../../../types';

import Styles from './AspectRatioDiv.module.scss';

export const AspectRatioDiv: React.FC<{ media?: Media }> = ({ media }) => {
  const { mobileAspect, desktopAspect } = media || {};

  const [aspectMobileWidth, aspectMobileHeight] = (mobileAspect || '1:1').split(':');
  const [aspectDesktopWidth, aspectDesktopHeight] = (desktopAspect || '16:9').split(':');
  const finalMobileAspect = (parseInt(aspectMobileHeight) / parseInt(aspectMobileWidth)) * 100;
  const finalDesktopAspect = (parseInt(aspectDesktopHeight) / parseInt(aspectDesktopWidth)) * 100;

  return (
    <Fragment>
      <OnMobileAndTabletOnly>
        {finalMobileAspect && <div style={{ paddingTop: `${finalMobileAspect}%` }} className={Styles.container} />}
      </OnMobileAndTabletOnly>
      <OnDesktopOnly>
        {finalDesktopAspect && <div style={{ paddingTop: `${finalDesktopAspect}%` }} className={Styles.container} />}
      </OnDesktopOnly>
    </Fragment>
  );
};
