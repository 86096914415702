import React from 'react';

import cx from 'clsx';

import type { OwnProps } from './types';

import * as Styles from './RichListItem.module.scss';

export const RichListItem: React.FC<React.PropsWithChildren<OwnProps>> = ({ children, size }) => {
  if (!children) {
    return null;
  }

  const richListItemClassNames = cx(Styles.li, {
    [Styles.small]: size && size === 'small',
  });

  return <li className={richListItemClassNames}>{children}</li>;
};
