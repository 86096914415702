import React from 'react';

import cx from 'clsx';

import type { OwnProps } from './types';

import * as Styles from './RichUnorderedList.module.scss';

export const RichUnorderedList: React.FC<React.PropsWithChildren<OwnProps>> = ({ children, withSpace }) => {
  if (!children) {
    return null;
  }

  const richUnorderedListClassNames = cx(Styles.ul, {
    [Styles.withSpace]: withSpace,
  });

  return <ul className={richUnorderedListClassNames}>{children}</ul>;
};
