import React, { Fragment } from 'react';

import { useQuery } from '@apollo/client';
import { cookieService } from '@utils/CookieService';

import { ProductPromo } from '../ProductPromo/ProductPromo';
import ProductPromoTripple from '../ProductPromoTripple/ProductPromoTripple';

import { CentraProductsQuery } from './CentraProductsQuery';
import { buildProductsList } from './utils';

export type FilterProduct = {
  product: string;
  name: string;
  variantName: string;
  available: boolean;
  uri: string;
  swatch: {
    hex: string;
    desc: string;
  } | null;
  category: string;
  categoryName: string;
  brandName: string;
  categoryUri: string;
  sku: string;
  showAsNew: boolean | null;
  showAsOnSale: boolean | null;
  price: string | null;
  isShopTheLook: boolean;
  priceBeforeDiscount: string | null;
  priceAsNumber: string | null;
  prices: {
    price: string;
    priceAsNumber: string;
    priceBeforeDiscount: string;
    priceBeforeDiscountAsNumber: string;
  }[];
  isRaffle?: boolean;
  categories: {
    category: string;
    name: string[];
  }[];
  media: Record<string, string[]>;
  items: {
    item: string;
    ean: string;
    name: string;
    sku: string;
    stock: string;
  }[];
  fit: string | null;
};

export type Products = {
  filterProducts: {
    products: FilterProduct[];
  };
};

export type OwnProps = {
  module: {
    title: string;
    headerText?: string;
    centraProducts?: string;
    gridType?: 'normal' | 'promo';
    width?: 'fullscreen' | 'container';
  };
};

export const CentraProducts: React.FC<OwnProps> = ({ module }) => {
  const { headerText, centraProducts, gridType = 'normal', width } = module;
  const currentMarket = cookieService.getMarket();
  const parsedProducts = JSON.parse(centraProducts);
  const productsList = buildProductsList(parsedProducts, currentMarket);
  const productIds = productsList.map((product: { product: string }) => product.product);
  const { loading, data } = useQuery(CentraProductsQuery, { variables: { ids: productIds } });

  return (
    <Fragment>
      {data?.filterProducts?.products && gridType !== 'promo' && (
        <ProductPromo width={width} header={headerText} products={data.filterProducts.products} />
      )}
      {data?.filterProducts?.products && gridType === 'promo' && (
        <ProductPromoTripple width={width} header={headerText} products={data.filterProducts.products} />
      )}
    </Fragment>
  );
};

export default CentraProducts;
