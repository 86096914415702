export const getCheckoutConfig = language => {
  const checkoutConfig = {
    ui: {
      tokenCookiePropName: 'token',
      intlTelInput: {
        isEnabled: false,
      },
      informationBox: {
        enabled: false,
        text: '',
      },
      initialMobileVisibleItems: {
        onSmallMobile: 1,
        onMediumMobile: 2,
      },
      ingridFieldsInformationText: {
        enabled: false,
        text: '',
      },
      freeShippingCountDown: {
        enabled: false,
        text: '', // Text message
        countDownTo: 100, // 100% value of the countdown component, should be number not a string
      },
      productItem: {
        isSkuVisible: false,
        isSizeVisible: true,
        isVariantNameVisible: true,
        withProductDetails: false,
        productUrlPrefix: 'product',
      },
      cartFooterNote: {
        enabled: false,
        html: '',
      },
      cartOrderSummary: {
        showTitle: true,
        shopMore: true,
      },
      cartPaymentLogoBar: {
        enabled: false,
      },
      personalInformation: {
        isRememberMeVisible: true,
      },
      additionalNotes: {
        enabled: false,
      },
      validation: {
        isDisabled: true,
      },
      orderSummary: {
        isVoucherVisible: true,
      },
      consentGroup: {
        termsLink: {
          isTermsLinkVisible: true,
          url: '/page/privacy-policy',
          secondUrl: '/page/terms-and-conditions',
        },
        newsletterTermsLink: {
          isTermsLinkVisible: false,
          url: '/page/terms-and-conditions',
        },
      },
      steps: {
        isIngridFieldsVisible: false,
      },
      voucher: {
        type: 'toggle',
        limit: 0, // 0 === no limit
      },
      layout: {
        type: 'flat',
        paymentOptionsDisplayDirection: 'row',
      },
      splitShippingCountry: {
        isEnabled: true, // Show country/state select on the top, outside of shipping address form
      },
      giftWrap: {
        isEnabled: false,
      },
      countryRestrictedLawInfo: {
        isEnabled: true,
      },
      additionalContactDetails: {
        isPhoneNumberVisible: false,
      },
      shippingMethodDescriptions: {
        enabled: true,
        inline: true,
        descriptions: {
          sek: 'Nunc semper sed augue in ornare. Integer id diam ult rices, vehicula orci non, com modo massa. Nulla imperdiet tincidunt sem et posuere. Nullam sit amet nulla pretium, congue ipsum id, maximus massa. Phasellus pharetra nec diam sed aliquam. Morbi pulvinar libero id mi tristique euismod. Curabitur nec orci nulla. Donec quis arcu dictum, varius tellus ac, dapibus arcu. Quisque malesuada eget dolor at varius. Donec rhoncus faucibus pellentesque.',
        },
      },
      useFacebookConversionApi: {
        isEnabled: false,
      },
      analyticsTracking: {
        isEnabled: true,
      },
      ga4analyticsTracking: {
        isEnabled: true,
      },
    },
    modules: {
      membership: {
        isEnabled: false,
        enabledTabs: ['personal', 'password', 'orders'],
      },
    },
    pathnames: {
      checkout: '/selection',
      failure: '/selection/failed',
      success: `/api/payment-return/selection/receipt`,
      wallet: '/selection?wallet',
      account: '/account',
    },
  };

  return checkoutConfig;
};

export const isCheckoutRelatedPage = (location: string) => {
  return location === '/selection';
};
