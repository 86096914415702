import React from 'react';

import { Block, ImageBanner, MediaIframe, VideoContent } from '@components/components';
import { Link } from '@core-frontend/components';
import cx from 'clsx';

import type { Media } from '../../../../../types';
import type { RichContent } from '../../../../../types/contentful/RichContent';

import { PanoramaMediaContent } from './components/PanoramaMediaContent/PanoramaMediaContent';

import Styles from './Panorama.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    image: Media;
    richContent?: RichContent | null;
    width?: 'fullscreen' | 'container';
    theme?: 'light' | 'dark';
  };
  priority?: boolean;
};

export const Panorama: React.FC<OwnProps> = ({ module, priority }) => {
  if (!module) {
    return null;
  }

  const { image, theme, width, richContent, slug } = module;
  const { links } = richContent || {};
  const linkUrl = links && links[0]?.url;
  const linkText = links && links[0]?.title;

  const { desktopImage, mobileImage, video, iframe } = image || {};

  const containerClassNames = cx({
    [Styles.container]: true,
    ...(richContent && 'contentTheme' in richContent && { [Styles[richContent.contentTheme]]: true }),
  });

  const renderCOntent = () => (
    <div className={Styles.wrapper}>
      <div className={containerClassNames}>
        {video && <VideoContent media={image} />}
        {(desktopImage || mobileImage) && <ImageBanner priority={priority} media={image} />}
        {iframe && <MediaIframe media={image} />}
        {richContent && (
          <div className={Styles.content}>
            <PanoramaMediaContent className={Styles.contentPadding} config={richContent} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Block width={width} theme={theme} id={slug}>
      {linkUrl ? (
        <Link className={Styles.containerLink} to={linkUrl} {...(linkText && { 'aria-label': linkText })}>
          {renderCOntent()}
        </Link>
      ) : (
        renderCOntent()
      )}
    </Block>
  );
};

export default Panorama;
