import { Divider } from '@components/components/MainProductsGrid/components';

export function addDividers(array: any[], dividersList: any[], after: any) {
  const result = [];

  for (let i = 0; i < array.length; i += 1) {
    const currentDivider = dividersList[i];
    const sysId = currentDivider?.content?.sys.id ?? '';

    if (currentDivider && array[i].length >= after) {
      result.push({
        id: `prod-${i}-${sysId}`,
        type: 'products',
        data: array[i],
      });
      result.push({
        id: `div-${i}-${sysId}`,
        type: 'divider',
        data: currentDivider,
      });
    } else {
      result.push({
        id: `prod-${i}-${sysId}`,
        type: 'products',
        data: array.slice(i).reduce((acc, a) => {
          return [...acc, ...a];
        }, []),
      });
      return result;
    }
  }

  return result;
}

export const dividersMap = {
  CfImageSingle: Divider,
};
