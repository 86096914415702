import React from 'react';

import { LazyImage } from '@components/components';

import type { OwnProps } from './types';

import Styles from './EmbeddedAsset.module.scss';

export const EmbeddedAsset: React.FC<OwnProps> = ({ node }) => {
  const fileUrl = node?.data?.target?.fields?.file?.url ?? null;
  const title = node?.data?.target?.fields?.title ?? null;
  const width = node?.data?.target?.fields?.file?.details?.image?.width ?? 0;
  const height = node?.data?.target?.fields?.file?.details?.image?.height ?? 0;
  const caption = node?.data?.target?.fields?.description;

  return (
    <LazyImage sources={true} width={width} height={height} src={`${fileUrl}?fit=scale&w=${width}`} alt={title}>
      {caption && <p className={Styles.caption}>{caption}</p>}
    </LazyImage>
  );
};
