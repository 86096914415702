import React, { Fragment } from 'react';

import { OnDesktopOnly } from '@components/layout-components';
import { Link } from '@core-frontend/components';
import cx from 'clsx';
import { useRouter } from 'next/router';

import { EditorialPageBreadcrumbs } from '../EditorialPageBreadcrumbs/EditorialPageBreadcrumbs';

import type { OwnProps } from './types.d';

import Styles from './PageTabs.module.scss';

export const PageTabs: React.FC<JSX.IntrinsicElements['nav'] & OwnProps> = props => {
  const { menu, pageTitle, className, ...rest } = props;
  const { menu: menuElements = [], descriptionText, name } = menu || {};
  const { asPath } = useRouter();

  if (!menu && !pageTitle && !descriptionText && !name) {
    return null;
  }

  const pageTabsClassNames = cx({
    [Styles.wrapper]: true,
    ...(className && { [className]: true }),
  });

  return (
    <Fragment>
      <OnDesktopOnly>
        <EditorialPageBreadcrumbs linkedPages={menu._backrefs.pages} />
      </OnDesktopOnly>
      {(name || pageTitle) && <h1 className={Styles.pageHeader}>{name || pageTitle}</h1>}
      {descriptionText && <div className={Styles.description}>{descriptionText}</div>}
      <nav className={pageTabsClassNames} {...rest}>
        {menuElements.map((element, index) => {
          if (element.__typename !== 'CfLink') {
            return null;
          }
          const { linkText, url } = element;
          return (
            <div
              key={`page-tabs-menu-item-${index}`}
              className={asPath === url ? cx(Styles.menu, Styles.active) : Styles.menu}
            >
              <Link to={url}>{linkText}</Link>
            </div>
          );
        })}
      </nav>
    </Fragment>
  );
};
