import { create } from 'zustand';

interface CartStore {
  isCartOpen: boolean;
  setCartOpen: (open: boolean) => void;
}

export const useCartStore = create<CartStore>(set => ({
  isCartOpen: false,
  setCartOpen: (open: boolean) => set(() => ({ isCartOpen: open })),
}));
