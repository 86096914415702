import { gql } from '@apollo/client';

export const LinkFragment = gql`
  fragment LinkFragment on CfLink {
    title
    linkText
    url
    external
    isTextPage
    textColor
    textSize
    linkPosition
  }
`;
