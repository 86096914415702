export const avaliableBlocks = {
  spacer: 'CfSpacer',
  products: 'CfCentraProducts',
  featuredproduct: 'CfFeaturedProduct',
  hero: 'CfHero',
  hero50: 'CfHero50',
  hero50textimage: 'CfHero50TextImage',
  panorama: 'CfPanorama',
  panorama50: 'CfPanorama50',
  tripplepromo: 'CfTripplePromo',
  tripplepromotext: 'CfTripplePromoText',
  promo50: 'CfPromo50',
  promo50small: 'CfPromo50Small',
  textbar: 'CfTextBar',
  centraProducts: 'CfCentraProducts',
  textintro: 'CfTextIntro',
  textquote: 'CfTextQuote',
  texteditor: 'CfTextEditor',
  instagram: 'CfInstagram',
  accordions: 'CfAccordion',
};
