import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import {
  EmbeddedAsset,
  RichBlockquote,
  RichHeading,
  RichHr,
  RichListItem,
  RichMark,
  RichOrderedList,
  RichTextParagraph,
  RichUnorderedList,
} from './components';

function stripTrailingParagraphNodes(richtext: any) {
  let shouldStop = false;
  const reversedContent = [...richtext.content].reverse();

  richtext.content = reversedContent
    .filter(node => {
      if (!shouldStop) {
        if (node.nodeType === 'paragraph') {
          const [{ value }] = node.content;
          if (value.trim()) {
            shouldStop = true;
            return true;
          }
          return false;
        } else {
          shouldStop = true;
          return true;
        }
      }
      return true;
    })
    .reverse();

  return richtext;
}

export const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      return <EmbeddedAsset node={node} />;
    },
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => <RichTextParagraph withSpace>{children}</RichTextParagraph>,
    [BLOCKS.HEADING_1]: (node: any, children: any) => (
      <RichHeading size={1} withSpace>
        {children}
      </RichHeading>
    ),
    [BLOCKS.HEADING_2]: (node: any, children: any) => (
      <RichHeading size={2} withSpace>
        {children}
      </RichHeading>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: any) => (
      <RichHeading size={3} withSpace>
        {children}
      </RichHeading>
    ),
    [BLOCKS.HEADING_4]: (node: any, children: any) => (
      <RichHeading size={4} withSpace>
        {children}
      </RichHeading>
    ),
    [BLOCKS.HEADING_5]: (node: any, children: any) => (
      <RichHeading size={5} withSpace>
        {children}
      </RichHeading>
    ),
    [BLOCKS.HEADING_6]: (node: any, children: any) => (
      <RichHeading size={6} withSpace>
        {children}
      </RichHeading>
    ),
    [BLOCKS.OL_LIST]: (node: any, children: any) => <RichOrderedList withSpace>{children}</RichOrderedList>,
    [BLOCKS.UL_LIST]: (node: any, children: any) => <RichUnorderedList withSpace>{children}</RichUnorderedList>,
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => <RichListItem size="small">{children}</RichListItem>,
    [BLOCKS.HR]: () => <RichHr />,
    [BLOCKS.QUOTE]: (node: any, children: any) => <RichBlockquote>{children}</RichBlockquote>,
  },
  renderMark: {
    [MARKS.BOLD]: (children: any) => <RichMark type="bold">{children}</RichMark>,
    [MARKS.ITALIC]: (children: any) => <RichMark type="italic">{children}</RichMark>,
    [MARKS.UNDERLINE]: (children: any) => <RichMark type="underline">{children}</RichMark>,
    [MARKS.CODE]: (children: any) => <RichMark type="code">{children}</RichMark>,
  },
  renderText: (text: string) => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
};

export const RichText = (content: any, skipEmpty?: boolean) => {
  let processedRichText = content;

  if (skipEmpty) {
    try {
      processedRichText = stripTrailingParagraphNodes(content);
    } catch (e) {}
  }

  return documentToReactComponents(processedRichText, options);
};
