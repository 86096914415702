import React from 'react';

import { Block, ImageBanner } from '@components/components';
import { Link } from '@core-frontend/components';
import cx from 'clsx';

import type { Media } from '../../../../../types';
import type { RichContent } from '../../../../../types/contentful/RichContent';

import { TripplePromoMediaContent } from './components/TriplePromoMediaContent/TripplePromoMediaContent';

import Styles from './TripplePromo.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    imageRight: Media | null;
    imageLeft: Media | null;
    imageCenter: Media | null;
    richContentRight?: RichContent | null;
    richContentLeft?: RichContent | null;
    richContentCenter?: RichContent | null;
    width?: 'fullscreen' | 'container';
    theme?: 'light' | 'dark';
  };
  priority?: boolean;
};

export const TripplePromo: React.FC<OwnProps> = ({ module, priority }) => {
  if (!module) {
    return null;
  }

  const { imageCenter, imageLeft, imageRight, richContentCenter, richContentLeft, richContentRight, width, slug } =
    module;
  const { links: linksLeft } = richContentLeft || {};
  const linkUrlLeft = linksLeft && linksLeft[0].url;
  const { links: linksRight } = richContentRight || {};
  const linkUrlRight = linksRight && linksRight[0].url;
  const { links: linksCenter } = richContentCenter || {};
  const linkUrlCenter = linksCenter && linksCenter[0].url;

  const renderMedia = (media: Media, content: RichContent, links?: string) => {
    const { desktopImage, mobileImage } = media || {};

    const containerClassNames = cx({
      [Styles.container]: true,
    });

    const contentClassNames = cx({
      [Styles.content]: true,
    });

    return (
      <div className={containerClassNames}>
        {links ? (
          <Link to={links}>
            {(desktopImage || mobileImage) && <ImageBanner priority={priority} media={media} />}
            {content && (
              <div className={contentClassNames}>
                <TripplePromoMediaContent config={content} />
              </div>
            )}
          </Link>
        ) : (
          <>
            {(desktopImage || mobileImage) && <ImageBanner priority={priority} media={media} />}
            {content && (
              <div className={contentClassNames}>
                <TripplePromoMediaContent config={content} />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const wrapperClassNames = cx({
    [Styles.wrapper]: true,
  });

  return (
    <Block width={width} theme="light" id={slug}>
      <div className={wrapperClassNames}>
        {renderMedia(imageLeft, richContentLeft, linkUrlLeft)}
        {renderMedia(imageCenter, richContentCenter, linkUrlCenter)}
        {renderMedia(imageRight, richContentRight, linkUrlRight)}
      </div>
    </Block>
  );
};

export default TripplePromo;
