import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import { RichText } from '@components/renderers/ContentfulPagesRenderer/components';
import type { OwnProps } from 'AlertBar';
import cx from 'clsx';

import Styles from './AlertBar.module.scss';

export const AlertBar: React.FC<OwnProps> = ({ warning, messageData, onAccept = () => {} }) => {
  const [open, setOpen] = useState(true);
  const { headerText, content, buttonLabel } = messageData || {};
  const contentText = content && JSON.parse(content);
  const portal = document.getElementById('portal');

  if (portal === null) {
    return null;
  }

  if ((!headerText || !contentText) && !buttonLabel) {
    return null;
  }

  const alertBarClassNames = cx({
    [Styles.container]: true,
    [Styles.warning]: warning,
  });

  const handleClose: React.MouseEventHandler<HTMLButtonElement> = event => {
    onAccept(event);
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return createPortal(
    <div className={alertBarClassNames}>
      <div className={Styles.content}>
        {headerText && <h4 className={Styles.header}>{headerText}</h4>}
        {contentText && <div>{RichText(contentText)}</div>}
      </div>
      {buttonLabel && (
        <button className={Styles.action} onClick={handleClose}>
          {buttonLabel}
        </button>
      )}
    </div>,
    portal,
  );
};
