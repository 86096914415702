export function chunk<T>(array: T[], size: number): T[][] {
  if (array.length === 0 || size <= 0) {
    return [];
  }

  const firstChunk = array.slice(0, size);
  const remainingArray = array.slice(size);

  return remainingArray.length > 0 ? [firstChunk, ...chunk(remainingArray, size)] : [firstChunk];
}
