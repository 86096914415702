import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import { RichText } from '@components/renderers/ContentfulPagesRenderer/components';
import { cookieService } from '@utils/CookieService';
import cx from 'clsx';

import { ArrowRight, Close } from '../../../public/images/icons';
import { useNewsletterStore } from '../../../store/newsletterStore';
import type { ContactFormData, Media } from '../../../types';

import Styles from './Newsletter.module.scss';

type Variables = {
  email: string;
  country: string | null;
};

type VariablesObject = {
  variables: Variables;
};

type OwnProps = {
  newsletterMedias: Media;
  newsletterContent: ContactFormData;
  newsletterSubscription: (variables: VariablesObject) => Promise<any>;
};

export const Newsletter: React.FC<OwnProps> = ({ newsletterSubscription, newsletterMedias, newsletterContent }) => {
  const { isNewsletterOpen, toggleNewsletter } = useNewsletterStore();
  const portal = document.getElementById('portal');

  const { formatMessage } = useIntl();

  const [inputMessage, setInputMessage] = useState('');
  const [consent, setConsent] = useState(false);
  const currentCountryCode = cookieService.getCountryCode();

  const desktopImage = newsletterMedias?.desktopImage?.file?.url;
  const mobileImage = newsletterMedias?.mobileImage?.file?.url;

  const header = newsletterContent?.headerText;
  const content = newsletterContent?.content;
  const contentText = content && JSON.parse(content);
  const consentText = newsletterContent?.consent;
  const consentParsedText = consentText && JSON.parse(consentText);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toggleConsent = () => {
    setConsent(consent => !consent);
  };

  const reset = () => {
    setInputMessage('');
  };

  const NewsletterClassNames = cx({
    [Styles.wrapper]: true,
  });

  useEffect(() => {
    if (isNewsletterOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [isNewsletterOpen]);

  if (!isNewsletterOpen || !portal) {
    return null;
  }

  const onSubmit = (e: any) => {
    const variables: Variables = { email: e.email, country: currentCountryCode };
    newsletterSubscription({
      variables: variables,
    }).then(response => {
      const { subscribed } = response.data.newsletterSubscription;
      if (subscribed) {
        setInputMessage(formatMessage({ id: 'email.success' }));
      } else {
        setInputMessage(formatMessage({ id: 'email.fail' }));
      }
    });
  };

  return createPortal(
    <div className={Styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} className={NewsletterClassNames}>
        <div
          className={Styles.close}
          onClick={() => {
            reset();
            setConsent(false);
            toggleNewsletter(false);
          }}
        >
          <Close width={'20px'} height={'20px'} />
        </div>
        <div className={Styles.photo}>
          <OnDesktopOnly>
            <img src={desktopImage} />
          </OnDesktopOnly>
          <OnMobileAndTabletOnly>
            <img src={mobileImage} />
          </OnMobileAndTabletOnly>
        </div>
        <div className={Styles.content}>
          {header && <h3>{header}</h3>}
          {contentText && <div className={Styles.paragraph}>{RichText(contentText)}</div>}
          <input
            id="newsletter-email"
            className={Styles.input}
            {...register('email', {
              required: 'This field is required',
              pattern: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i,
            })}
            placeholder={formatMessage({ id: 'input.placeholder' })}
          ></input>
          <p className={Styles.message}>
            {errors.email && typeof errors.email.message === 'string' && errors.email.message}
          </p>
          {inputMessage !== '' && (
            <span className={Styles.message} id="newsletter-message">
              {inputMessage}
            </span>
          )}
          <div className={Styles.consent}>
            <input
              {...register('consent', { required: 'This field is required' })}
              id="newsletter-chexbox"
              className={consent ? cx(Styles.checkbox, Styles.active) : Styles.checkbox}
              onClick={toggleConsent}
              type="checkbox"
            />
            {consentParsedText && <div>{RichText(consentParsedText)}</div>}
          </div>
          <p className={Styles.message}>
            {errors.consent && typeof errors.consent.message === 'string' && errors.consent.message}
          </p>
          <button type="submit" aria-label="Submit form" className={Styles.submitButton}>
            <ArrowRight />
          </button>
        </div>
      </form>
    </div>,
    portal,
  );
};
