import React from 'react';

import cx from 'clsx';
import type { OwnProps } from 'InfoButton';

import { Info as InfoIcon } from '../../../public/images/icons';

import Styles from './InfoButton.module.scss';

export const InfoButton: React.FC<OwnProps> = () => {
  const infoButtonWrapperStyles = cx({
    [Styles.wrapper]: true,
  });

  return (
    <div className={infoButtonWrapperStyles}>
      <a target="_blank" rel="noopener noreferrer" href="/page/shipping-returns" className={Styles.link}>
        <span className={Styles.description}>SHIPPING & RETURNS</span>
        <InfoIcon />
      </a>
    </div>
  );
};
