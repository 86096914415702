import React, { Fragment, useEffect, useState } from 'react';

import { trackGA4ViewItem } from '@core-analytics/getAnalyticsFunctions';
import { getProductJSON } from '@core-analytics/schemas';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { getProductSchemaJSON } from '../../../core-components/schema';

import type { OwnProps } from './types';

export const ProductMetaData: React.FC<OwnProps> = ({ product }) => {
  const {
    media,
    categoryUri,
    uri,
    metaTitle,
    metaDescription,
    name,
    description,
    brandName,
    priceAsNumber,
    price,
    available,
    sku,
  } = product;
  const { standard } = media || {};
  const { asPath } = useRouter();
  const combinedUri = `${categoryUri}/${uri}`;
  const productUrl = `${process.env.NEXT_PUBLIC_URL}/${combinedUri}`;

  const [productDetailsAnalyticsSchema, setProductDetailsAnalyticsSchema] = useState<string>();

  useEffect(() => {
    const productSchema = product && getProductJSON({ ...product, uri: asPath }, asPath);
    setProductDetailsAnalyticsSchema(productSchema);
  }, [product]);

  useEffect(() => {
    if (productDetailsAnalyticsSchema?.length) {
      trackGA4ViewItem(true);
    }
  }, [productDetailsAnalyticsSchema]);

  return (
    <Fragment>
      <Head>
        <title>{name}</title>
        <meta name="title" content={metaTitle === '' ? name : metaTitle} />
        <meta property="description" content={metaDescription === '' ? description : metaDescription} />
        <meta property="og:title" content={name} />
        <meta property="og:site_name" content={brandName} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={standard ? standard[0] : ''} />
        <meta property="product:brand" content={brandName} />
        <meta property="product:price:amount" content={`${priceAsNumber}`} />
        <meta property="product:price:currency" content={price ? price.slice(-3) : ''} />
        <meta property="product:availability" content={available ? 'in stock' : 'out of stock'} />
        <meta property="product:retailer_item_id" content={sku} />
        <meta property="og:url" content={`${productUrl}/`} />
        <link rel="canonical" href={`/${categoryUri}/${uri}`} />
        <script type="application/ld+json" data-schema="product-detail">
          {getProductSchemaJSON(product, combinedUri)}
        </script>
      </Head>
      <script type="application/json" data-analytics="product-detail">
        {productDetailsAnalyticsSchema}
      </script>
    </Fragment>
  );
};
