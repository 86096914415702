import React from 'react';

import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import { Link } from '@core-frontend/components';
import type { OwnProps } from 'BackButton';
import cx from 'clsx';

import { ArrowLeft as ArrowLeftIcon, ChevronLeft as ChevronLeftIcon } from '../../../public/images/icons';

import Styles from './BackButton.module.scss';

export const BackButton: React.FC<OwnProps> = () => {
  const backButtonWrapperStyles = cx({
    [Styles.wrapper]: true,
  });

  return (
    <div className={backButtonWrapperStyles}>
      <Link to="/" className={Styles.link}>
        <OnDesktopOnly>
          <ArrowLeftIcon width="20" height="20" />
        </OnDesktopOnly>
        <OnMobileAndTabletOnly>
          <ChevronLeftIcon width="9" height="15" />
        </OnMobileAndTabletOnly>
        <span className={Styles.description}>CONTINUE SHOPPING</span>
      </Link>
    </div>
  );
};
