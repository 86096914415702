import React from 'react';

import type {
  HorizontalPositions,
  VerticalPositions,
} from '@components/renderers/ContentfulPagesRenderer/components/ShortText/types';
import cx from 'clsx';

import { ArrowRight as ArrowRightIcon } from '../../../../../../../public/images/icons';
import { RichText } from '../../../RichText';

import type { OwnProps } from './types';

import Styles from './Promo50MediaContent.module.scss';

export const Promo50MediaContent: React.FC<JSX.IntrinsicElements['div'] & OwnProps> = ({
  config,
  className,
  numberAttribute,
  imagePosition,
  ...rest
}) => {
  const {
    headerText,
    headerTextAlign,
    headerTextSize,
    content,
    contentAlign,
    contentSize,
    links,
    desktopPosition,
    mobilePosition,
    contentTheme = 'light',
  } = config;

  if (!headerText && !content && !links) {
    return null;
  }

  const [mobileHorizontal, mobileVertical] = (mobilePosition || 'middle-center').split('-') as [
    HorizontalPositions,
    VerticalPositions,
  ];

  const [desktopHorizontal, desktopVertical] = (desktopPosition || 'middle-center').split('-') as [
    HorizontalPositions,
    VerticalPositions,
  ];

  const contentText = content && JSON.parse(content);

  const linkUrl = links?.[0]?.url;

  // Media content settings
  const mediaContentClassNames = cx(
    Styles.content,
    Styles[`mobile_${mobileHorizontal}`],
    Styles[`mobile_${mobileVertical}`],
    Styles[`desktop_${desktopHorizontal}`],
    Styles[`desktop_${desktopVertical}`],
    {
      [Styles[contentTheme]]: true,
      [Styles.big]: true,
      ...(className && { [className]: true }),
    },
  );
  return (
    <div className={mediaContentClassNames} {...rest}>
      {numberAttribute && (
        <div className={imagePosition === 'left' ? cx(Styles.attribute, Styles.rightSide) : Styles.attribute}>
          {numberAttribute}
        </div>
      )}
      <div className={imagePosition === 'left' ? cx(Styles.wrapper, Styles.rightSide) : Styles.wrapper}>
        {headerText && (
          <h3
            className={Styles.title}
            {...((headerTextAlign || headerTextSize) && {
              style: {
                ...(headerTextAlign && { textAlign: headerTextAlign }),
                ...(headerTextSize && { fontSize: headerTextSize }),
              },
            })}
          >
            {headerText}
          </h3>
        )}
        {contentText && (
          <div
            className={Styles.description}
            {...((contentAlign || contentSize) && {
              style: {
                ...(contentAlign && { textAlign: contentAlign }),
                ...(contentSize && { fontSize: contentSize }),
              },
            })}
          >
            {RichText(contentText)}
          </div>
        )}
        {linkUrl && (
          <div className={Styles.link}>
            <ArrowRightIcon className="inline-block" />
          </div>
        )}
      </div>
    </div>
  );
};
