import React from 'react';

import { AspectRatioDiv, Block, ImageBanner, MediaIframe, VideoContent } from '@components/components';
import { Link } from '@core-frontend/components';
import cx from 'clsx';

import type { Media } from '../../../../../types';
import type { RichContent } from '../../../../../types/contentful/RichContent';

import { Panorama50MediaContent } from './components/Panorama50MediaContent/Panorama50MediaContent';

import Styles from './Panorama50.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    imageLeft: Media;
    imageRight: Media;
    richContentLeft?: RichContent | null;
    richContentRight?: RichContent | null;
    width?: 'fullscreen' | 'container';
    theme?: 'light' | 'dark';
  };
  priority?: boolean;
};

export const Panorama50: React.FC<OwnProps> = ({ module, priority }) => {
  if (!module) {
    return null;
  }

  const { imageLeft, imageRight, theme, width, richContentLeft, richContentRight, slug } = module;

  const renderContent = (media: Media, content: RichContent) => {
    const { desktopImage, mobileImage, video, iframe } = media || {};
    return (
      <>
        {video && <VideoContent media={media} />}
        {(desktopImage || mobileImage) && <ImageBanner priority={priority} media={media} />}
        {iframe && <MediaIframe media={media} />}
        {!desktopImage && !mobileImage && !iframe && !video && <AspectRatioDiv media={media} />}
        {content && (
          <div className={Styles.content}>
            <Panorama50MediaContent className={Styles.contentPadding} config={content} />
          </div>
        )}
      </>
    );
  };

  const renderMedia = (media: Media, content: RichContent) => {
    const { links } = content || {};
    const linkUrl = links && links[0]?.url;
    const linkText = links && links[0]?.title;

    const containerClassNames = cx({
      [Styles.container]: true,
      ...(content && 'contentTheme' in content && { [Styles[content.contentTheme]]: true }),
    });

    return (
      <div className={containerClassNames}>
        {linkUrl ? (
          <Link className={Styles.containerLink} to={linkUrl} {...(linkText && { 'aria-label': linkText })}>
            {renderContent(media, content)}
          </Link>
        ) : (
          renderContent(media, content)
        )}
      </div>
    );
  };

  return (
    <Block width={width} theme={theme} id={slug}>
      <div className={Styles.wrapper}>
        {renderMedia(imageLeft, richContentLeft)}
        {renderMedia(imageRight, richContentRight)}
      </div>
    </Block>
  );
};

export default Panorama50;
