import React from 'react';

import { Block } from '@components/components';
import cx from 'clsx';

import { RichText } from '..';

import Styles from './TextIntro.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    content: string;
    textAlign: 'center' | 'left' | 'justify' | 'right';
    headerText: string;
    width?: 'fullscreen' | 'container';
  };
};

export const TextIntro: React.FC<OwnProps> = ({ module }) => {
  if (!module) {
    return null;
  }

  const { content, textAlign, width, headerText, slug } = module;

  const renderContent = (content: string) => {
    const contentText = content && JSON.parse(content);

    const containerClassNames = cx({
      [Styles.container]: true,
    });

    return (
      <div className={containerClassNames} style={{ ...(textAlign && { textAlign: textAlign }) }}>
        {headerText && <h3 className={Styles.headerText}>{headerText}</h3>}
        <div className={Styles.contentText}>{RichText(contentText)}</div>
      </div>
    );
  };

  return (
    <Block width={width} theme="light" id={slug}>
      <div className={Styles.wrapper}>{renderContent(content)}</div>
    </Block>
  );
};

export default TextIntro;
