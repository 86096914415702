import { gql } from '@apollo/client';

import { LinkFragment } from './LinkFragment';

export const MenusFragment = gql`
  fragment MenusFragment on CfMenus {
    title
    name
    slug
    isTextPage
    external
    url
    menu {
      ...LinkFragment
    }
  }
  ${LinkFragment}
`;
