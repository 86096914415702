import type { CSSProperties } from 'react';
import React, { Fragment } from 'react';
import LazyLoad from 'react-lazyload';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import cx from 'clsx';
import Head from 'next/head';

import Styles from './LazyImage.module.scss';

interface OwnProps {
  sources?: boolean;
  priority?: boolean;
  objectFit?: CSSProperties['objectFit'];
}

export const LazyImage: React.FC<JSX.IntrinsicElements['img'] & OwnProps> = props => {
  const { id, src, width, height, priority = false, objectFit, sources, className, style, children, ...rest } = props;

  const imageClassNames = cx({
    [Styles.image]: true,
    ...(className && { [className]: true }),
  });

  const imageStyles = {
    ...style,
    ...(objectFit && { objectFit }),
  };

  return (
    <Fragment>
      {priority && (
        <Head>
          <link rel="preload" href={`${src}&fm=webp`} type="image/webp" as="image" />
        </Head>
      )}
      <LazyLoad key={id || src} height={height} cla once={true} placeholder={<div className={Styles.placeholder} />}>
        <TransitionGroup>
          <CSSTransition timeout={500} classNames="fade" appear={true}>
            <picture className={Styles.picture}>
              {sources && <source srcSet={`${src}&fm=webp`} type="image/webp" />}
              <img
                style={imageStyles}
                className={imageClassNames}
                height={height}
                width={width}
                src={`${src}&fm=jpg&fl=progressive`}
                {...rest}
              />
              {children && children}
            </picture>
          </CSSTransition>
        </TransitionGroup>
      </LazyLoad>
    </Fragment>
  );
};
