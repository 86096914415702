import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

export const useClickOutsideRef = <T extends HTMLElement>(
  callback?: (event: MouseEvent) => void,
): { ref: MutableRefObject<T> } => {
  const wrapperRef: MutableRefObject<T | null> = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isOutsideClick =
        wrapperRef.current?.contains && event.target instanceof Node && !wrapperRef.current.contains(event.target);

      if (!isOutsideClick) {
        return;
      }

      callback?.(event);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, callback]);

  return {
    ref: wrapperRef,
  };
};
