import React from 'react';

import cx from 'clsx';
import type { OwnProps } from 'HamburgerButton';

import { Close as CloseIcon, Hamburger as HamburgerIcon } from '../../../public/images/icons';

import Styles from './HamburgerButton.module.scss';

export const HamburgerButton: React.FC<OwnProps> = ({ scrolled, menuOpened, onClick }) => {
  const hamburgerButtonWrapperStyles = cx(Styles.wrapper, {
    [Styles.scrolled]: scrolled,
    [Styles.menuOpened]: menuOpened,
  });

  return (
    <div className={hamburgerButtonWrapperStyles} onClick={onClick}>
      <button className={Styles.button} aria-label="Menu button" type="button" onClick={onClick}>
        {!menuOpened && <HamburgerIcon className="inline-block" />}
        {menuOpened && <CloseIcon className="inline-block" />}
      </button>
    </div>
  );
};
