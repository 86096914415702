import React from 'react';
import Media from 'react-media';

export const OnMobileAndTabletOnly: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <Media query="(max-width: 1023px)" defaultMatches={true} render={() => children} />
);

export const OnBigDesktopOnly: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <Media query="(min-width: 1440px)" defaultMatches={true} render={() => children} />
);

export const OnMobileOnly: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <Media query="(max-width: 767px)" defaultMatches={true} render={() => children} />
);

export const OnDesktopOnly: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <Media query="(min-width: 1024px)" defaultMatches={true} render={() => children} />
);

export const OnDesktopWithHoverOnly: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <Media
    query="(min-width: 1024px) and (hover: hover) and (pointer: fine)"
    defaultMatches={true}
    render={() => children}
  />
);
export const OnIEOnly: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <Media
    query="all and (-ms-high-contrast: none), (-ms-high-contrast: active)"
    defaultMatches={true}
    render={() => children}
  />
);

export const OnTabletOnly: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <Media
    query="(min-width: 768px) and (hover: none) and (pointer: coarse)"
    defaultMatches={true}
    render={() => children}
  />
);
