import React from 'react';

import { createChunk } from '@utils/createChunk';

import type { GridConfig } from './types';

import Styles from './Multigrid.module.scss';

export const returnGrid = (
  allElements: any[],
  config: GridConfig,
  renderer: (element: any, ratio?: number, width?: number, height?: number) => React.ReactNode,
) => {
  const pageLength = config.config.length;
  // create chunks which have same size as gridConfig
  return createChunk(allElements, pageLength).map((element: any[], index) => {
    return (
      <div
        style={{
          display: 'grid',
          alignItems: 'stretch',
          gridTemplateColumns: `repeat(${config.cols}, 1fr)`,
          gridTemplateRows: `repeat(${config.rows})`,
        }}
        key={index}
      >
        {element.map((child, index) => {
          const { gridArea } = config.config[index];
          const size = {
            height: gridArea[2] - gridArea[0],
            width: gridArea[3] - gridArea[1],
            ratio: (gridArea[2] - gridArea[0]) / (gridArea[3] - gridArea[1]), //@todo passing this will keep same height for the rows, but the image will not cover the whole area. to discuss if we change it to background image
          };
          return (
            <div className={Styles.wrapper} key={index.toString()} style={{ gridArea: gridArea.join('/') }}>
              {renderer(child, size.ratio, size.width, size.height)}
            </div>
          );
        })}
      </div>
    );
  });
};
