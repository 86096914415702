import { gql } from '@apollo/client';
import { ProductPricesFragment } from '@components/fragments/ProductPricesFragment';

export const CentraProductsQuery = gql`
  ${ProductPricesFragment}
  query productsByIds($ids: [String!]) {
    filterProducts(filters: { products: $ids }) {
      products {
        product
        name
        variantName
        available
        uri
        swatch {
          hex
          desc
        }
        category
        categoryName
        brandName
        categoryUri
        sku
        showAsNew
        showAsOnSale
        price
        isShopTheLook: shop_the_look_text
        priceBeforeDiscount
        priceAsNumber
        ...ProductPrices
        categories {
          uri
          name
          category
        }
        media {
          blur
          thumb
          standard
          full
          big
          new_thumb
          new_small
          new_small_x2
          new_medium
          new_medium_x2
          new_standard
          new_standard_x2
          new_big
          new_big_x2
        }
        items {
          item
          ean
          name
          sku
          stock
        }
      }
    }
  }
`;
