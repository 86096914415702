import React from 'react';

import Head from 'next/head';

import type { OwnProps } from './types.d';

export const PageMetaData: React.FC<OwnProps> = ({ data }) => {
  if (!data) {
    return null;
  }
  const { pageTitle, metaTitle, metaDescription } = data;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
    </Head>
  );
};
