import React from 'react';

import { NavigationBuilderMenu } from '@components/components';
import { NavigationBuilder } from '@components/renderers/ContentfulPagesRenderer/components';
import { Sheet, SheetContent } from '@components/ui/sheet';

import { Search } from '../Search/Search';

interface MobileNavigationProps {
  show: boolean;
  onToggle: () => void;
}

export const MobileNavigation = ({ show, onToggle }: MobileNavigationProps) => {
  return (
    <NavigationBuilder slug="mobile-navigation">
      {({ categoryTreeItems, categories, cfPages, customItems, navigationMedia }) => (
        <Sheet open={show} onOpenChange={onToggle}>
          <SheetContent showClose side="left" className="w-full px-0">
            <Search menuToggle={onToggle} />
            <NavigationBuilderMenu
              categoryTreeItems={categoryTreeItems}
              customItems={customItems}
              onClick={onToggle}
              pageAndCategories={{ cfPages, categories }}
              navigationMedia={navigationMedia}
            />
          </SheetContent>
        </Sheet>
      )}
    </NavigationBuilder>
  );
};
