import React, { useEffect, useRef, useState } from 'react';

import { Block, ProductItem } from '@components/components';
import { trackGA4ProductList } from '@core-analytics/getAnalyticsFunctions';
import { getProductListJSON } from '@core-analytics/schemas';
import cx from 'clsx';
import { useRouter } from 'next/router';

import Styles from './ProductPromo.module.scss';

interface OwnProps {
  header?: string;
  width?: 'fullscreen' | 'container';
  products: any[];
}

export const ProductPromo: React.FC<OwnProps> = ({ products, header, width = 'container' }) => {
  const productPromoClassNames = cx({
    [Styles.container]: true,
  });
  const filteredOutProducts = products.slice(0, 4);
  const router = useRouter();
  const [productsAnalytics, setProductsAnalytics] = useState('');

  const productListNameRef = useRef();

  useEffect(() => {
    if (products?.length > 0) {
      const analytics = getProductListJSON(products, router.asPath);
      setProductsAnalytics(analytics);
    }
  }, [products]);

  useEffect(() => {
    if (productsAnalytics && productsAnalytics.length > 0) {
      trackGA4ProductList(true, productListNameRef.current);
    }
  }, [productsAnalytics]);

  return (
    <Block width={width} theme="light">
      <div className={productPromoClassNames}>
        {filteredOutProducts.length > 0 && header && <h3 className={Styles.header}>{header}</h3>}
        <div className={Styles.wrapper}>
          <div data-testid="product-grid" className={Styles.grid}>
            {filteredOutProducts.map((element, index) => {
              return (
                <ProductItem key={`product-promo-item-${index}`} product={element} noHoverAction={true}></ProductItem>
              );
            })}
          </div>
        </div>
      </div>
      {productsAnalytics && productsAnalytics.length > 0 && (
        <div ref={productListNameRef} data-analytics-category="productPromo">
          <script data-analytics="product-list" type="application/json">
            {productsAnalytics}
          </script>
        </div>
      )}
    </Block>
  );
};

export default ProductPromo;
