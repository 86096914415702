import React, { useCallback, useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import { MAX_LAYOUT_WIDTH } from '@core-utils/constants';
import { useCurrentWidth } from '@utils/hooks';
import cx from 'clsx';

import type { OwnProps } from './types';

import Styles from './VideoContent.module.scss';

export const VideoContent: React.FC<JSX.IntrinsicElements['video'] & OwnProps> = ({
  media,
  priority,
  className,
  style,
  ...rest
}) => {
  const { video, mobileAspect, desktopAspect } = media || {};
  const containerRef = useRef(null);
  const [componentWidth, setComponentWidth] = useState();
  const windowWidth = useCurrentWidth();

  const imageWidth = componentWidth || MAX_LAYOUT_WIDTH;

  const videoUrl = video.file.url;
  const [aspectMobileWidth, aspectMobileHeight] = (mobileAspect || '1:1').split(':');
  const [aspectDesktopWidth, aspectDesktopHeight] = (desktopAspect || '16:9').split(':');
  const finalMobileAspect = (parseInt(aspectMobileHeight) / parseInt(aspectMobileWidth)) * 100;
  const finalMobileHeight = parseInt(aspectMobileHeight) / parseInt(aspectMobileWidth);
  const finalDesktopAspect = (parseInt(aspectDesktopHeight) / parseInt(aspectDesktopWidth)) * 100;
  const finalDesktopHeight = parseInt(aspectDesktopHeight) / parseInt(aspectDesktopWidth);

  const wrapperRef = useCallback(node => {
    if (node) {
      containerRef.current = node;
      setComponentWidth(node.offsetWidth);
    }
  }, []);

  /*   useEffect(() => {
    window.objectFitPolyfill();
  }, []); */

  useEffect(() => {
    if (containerRef.current) {
      setComponentWidth(containerRef.current.offsetWidth);
    }
  }, [windowWidth]);

  if (!videoUrl) {
    return null;
  }

  const mediaVideoContentClassNames = cx(Styles.container, {
    ...(className && { [className]: true }),
  });

  const renderDesktopVideo = () => {
    return (
      <TransitionGroup>
        <CSSTransition timeout={500} classNames="fade" appear={true}>
          <div
            className={mediaVideoContentClassNames}
            style={{
              width: '100%',
              height: `${imageWidth * finalDesktopHeight}px`,
              ...style,
              paddingTop: `${finalDesktopAspect}%`,
            }}
          >
            <video src={`${videoUrl}?w=${imageWidth}`} autoPlay muted loop playsInline />
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  };
  const renderMobileVideo = () => {
    return (
      <TransitionGroup>
        <CSSTransition timeout={500} classNames="fade" appear={true}>
          <div
            className={mediaVideoContentClassNames}
            style={{
              width: '100%',
              height: `${imageWidth * finalMobileHeight}px`,
              ...style,
              paddingTop: `${finalMobileAspect}%`,
            }}
          >
            <video src={`${videoUrl}?w=${imageWidth}`} autoPlay muted loop playsInline />
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  };

  return (
    <div ref={wrapperRef} className={Styles.wrapper}>
      <OnMobileAndTabletOnly>
        {priority ? (
          renderMobileVideo()
        ) : (
          <LazyLoad height={imageWidth * finalMobileHeight} once={true}>
            {renderMobileVideo()}
          </LazyLoad>
        )}
      </OnMobileAndTabletOnly>
      <OnDesktopOnly>
        {priority ? (
          renderDesktopVideo()
        ) : (
          <LazyLoad height={imageWidth * finalDesktopHeight} once={true}>
            {renderDesktopVideo()}
          </LazyLoad>
        )}
      </OnDesktopOnly>
    </div>
  );
};
