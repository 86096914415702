import React from 'react';

import { Link } from '@core-frontend/components/Link';

import { ProductPrice } from '..';

import type { OwnProps } from './types';

import * as Styles from './ProductInfoBox.module.scss';

export const ProductInfoBox: React.FC<OwnProps> = ({ product, hero, placeholder }) => {
  const { uri, categoryUri, name, media } = product;
  const productUri = categoryUri ? `/${categoryUri}/${uri}` : `/${uri}`;
  const thumb = media.thumb ? media.thumb[0] : placeholder;

  return (
    <div className={hero ? Styles.heroWrapper : Styles.wrapper}>
      <Link to={productUri}>
        <div className={Styles.hoverBar} />
        <div className={Styles.productInfo}>
          <span className={Styles.name}>{name}</span>
          <div className={Styles.price}>
            <ProductPrice product={product} />
          </div>
        </div>
        <div className={Styles.thumb}>{<img src={thumb} alt={name} />}</div>
      </Link>
    </div>
  );
};
