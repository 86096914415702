import React from 'react';

import cx from 'clsx';

import type { OwnProps } from './types';

import * as Styles from './RichOrderedList.module.scss';

export const RichOrderedList: React.FC<React.PropsWithChildren<OwnProps>> = ({ children, withSpace }) => {
  if (!children) {
    return null;
  }

  const richOrderedListClassNames = cx(Styles.ol, {
    [Styles.withSpace]: withSpace,
  });

  return <ol className={richOrderedListClassNames}>{children}</ol>;
};
