import { gql } from '@apollo/client';

export const MessageBarFragment = gql`
  fragment MessageBarFragment on CfMessageBar {
    slug
    headerText
    buttonLabel
    content
  }
`;
