import React from 'react';

import { isInStock } from '@utils/isInStock';
import cx from 'clsx';

import { ProductPrice } from '../';

import type { OwnProps } from './types';

import Styles from './ProductItemInfo.module.scss';

export const ProductItemInfo: React.FC<OwnProps> = ({ product, textColor }) => {
  const { name, uri, sku, items } = product;

  const classNames = cx(Styles.productInfo, {
    [Styles.soldOut]: !isInStock(items),
  });

  return (
    <div className={classNames} itemProp="itemListElement" data-url={uri} data-sku={sku} style={{ color: textColor }}>
      <div className={Styles.infoRow}>
        <div className={cx(Styles.labelText)} style={{ color: textColor }}>
          {name}
        </div>
      </div>
      <ProductPrice product={product} grid />
    </div>
  );
};
