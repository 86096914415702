import React from 'react';

import { useQuery } from '@apollo/client';
import cx from 'clsx';
import type { OwnProps } from 'MinicartButton';

import { Bag as BagIcon } from '../../../public/images/icons/icons';
import { useCartStore } from '../../../store/cartStore';

import { MINICART_BUTTON_QUERY } from './MinicartButtonQuery';

import Styles from './MinicartButton.module.scss';

export const MinicartButton: React.FC<OwnProps> = ({ transparent }) => {
  const { data } = useQuery(MINICART_BUTTON_QUERY);
  const { setCartOpen } = useCartStore();

  const minicartButtonStyles = cx({
    [Styles.wrapper]: true,
  });

  const minicartIconButtonStyles = cx({
    [Styles.cart]: true,
    [Styles.transparent]: !!transparent,
  });

  const itemsQuantity = data?.selection?.selection?.items?.reduce((acc: number, item: any) => acc + item.quantity, 0);

  const handleClick = (event: React.MouseEvent) => {
    setCartOpen(true);
  };

  const content = (
    <>
      <BagIcon />
      {itemsQuantity > 0 && <span className={Styles.minicartQuantity}>{itemsQuantity}</span>}
    </>
  );

  return (
    <div className={minicartButtonStyles}>
      <div
        id="cartButton"
        aria-label="Toggle Cart"
        onClick={handleClick}
        role="presentation"
        className={cx(minicartIconButtonStyles, Styles.button)}
      >
        {content}
      </div>
      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
      <a href="/selection" className={cx(minicartIconButtonStyles, Styles.link)}>
        {content}
      </a>
    </div>
  );
};
