import React from 'react';

import cx from 'clsx';

import type { OwnProps } from './types';

import Styles from './RichMark.module.scss';

export const RichMark: React.FC<React.PropsWithChildren<OwnProps>> = ({ type, children }) => {
  const richMarkClassNames = cx(Styles.richMark, {
    // TODO: update type
    [(Styles as any)[type]]: type !== undefined && type !== null,
  });

  if (!children) {
    return null;
  }

  return <span className={richMarkClassNames}>{children}</span>;
};
