import React, { useEffect } from 'react';

import { getCheckoutConfig } from '@utils/checkout';
import type { OwnProps } from 'CheckoutScript';

export const CheckoutScript: React.FunctionComponent<OwnProps> = ({ language, CheckoutLanguageOverrides }) => {
  useEffect(() => {
    const renderScript = () => {
      const vendor = document.createElement('script');
      const app = document.createElement('script');
      const stylesheet = document.createElement('link');

      global.multistepCheckoutApiUrl = process.env.NEXT_PUBLIC_CENTRA_API_URL!;
      global.multistepCheckoutLocale = language;
      global.multistepCheckoutMultilanguage = true;
      global.multistepCheckoutLocaleMessages = CheckoutLanguageOverrides;
      global.multistepCheckoutConfig = getCheckoutConfig('en');
      global.multistepCheckoutLocaleMessages = {
        en: {
          newsletter_checkbox_label: 'Sign up for email updates and promotions',
          checkout_shop_more_label: 'Continue shopping',
          terms_and_conditions_label: 'I have read and agree to the',
          terms_and_conditions_link_label: 'privacy policy',
          terms_and_conditions_second_label: 'and',
          terms_and_conditions_link_second_label: 'terms & conditions',
        },
      };

      stylesheet.type = 'text/css';
      stylesheet.rel = 'stylesheet';
      stylesheet.href = process.env.NEXT_PUBLIC_CHECKOUT_CSS;
      vendor.src = process.env.NEXT_PUBLIC_CHECKOUT_VENDOR!;
      app.src = process.env.NEXT_PUBLIC_CHECKOUT_APP!;
      vendor.async = true;
      app.async = true;

      if (!window.store) {
        document.head.prepend(stylesheet);
        document.body.appendChild(vendor);
        document.body.appendChild(app);
      }
    };

    if (!global.multistepCheckoutApiUrl) {
      renderScript();
    }
  }, [language, CheckoutLanguageOverrides]);

  return null;
};

export default CheckoutScript;
