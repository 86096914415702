import { priceForCurrentPricelist } from '@utils/prices';
import { $TSFixMeRecord } from '@utils/tsfixme';
import get from 'lodash/get';
import { z } from 'zod';

import { Product } from '../../types/centra-api';
import { isBrowser } from '../utils/global';

import { getGA4ProductSchema } from './getAnalyticsFunctions';

const getProductItems = (product: any, productUri: any) => {
  if (!Array.isArray(product.items)) {
    return [];
  }

  return product.items.map((item: any) => {
    const productPrice = priceForCurrentPricelist(product);
    return {
      '@type': 'Offer',
      availability: item.stock === 'yes' ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      gtin13: item.ean,
      itemCondition: 'https://schema.org/NewCondition',
      itemOffered: {
        '@context': 'http://schema.org',
        '@type': 'Product',
        sku: product.sku,
        url: productUri || '',
      },
      name: `${product.name} - ${item.name}`,
      price: productPrice?.price ?? '',
      priceCurrency: productPrice?.price.slice(-3) ?? '',
    };
  });
};

const getProductsList = (products: any) => {
  if (!Array.isArray(products)) {
    return [];
  }

  const filteredProducts = products.filter((product: any) => product.price);

  return filteredProducts.map((product: $TSFixMeRecord<Product>, index: number) => {
    const productPrice = priceForCurrentPricelist(product);
    const priceBeforeDiscountAsNumberSchema = z.number().safeParse(Number(productPrice?.priceBeforeDiscountAsNumber));
    return {
      '@type': 'Product',
      brand: product.brandName,
      category: product?.categoryUri,
      color: product.variantName,
      description: product.description,
      fullPrice: productPrice?.priceBeforeDiscountAsNumber,
      ga4: getGA4ProductSchema(product, index),
      image: `${get(product, 'media.standard.0')}`,
      name: product.name,
      offers: {
        '@type': 'AggregateOffer',
        price: productPrice?.priceAsNumber,
        priceCurrency: `${productPrice?.price.slice(-3)}`,
        highPrice: productPrice?.priceAsNumber,
        lowPrice: productPrice?.priceAsNumber,
        offerCount: `${product.items ? product.items.length : 0}`,
        offers: getProductItems(product, product.uri),
      },
      position: index,
      priceReduction: priceBeforeDiscountAsNumberSchema.success
        ? String(priceBeforeDiscountAsNumberSchema.data - productPrice?.priceAsNumber)
        : '',
      priceTax: '',
      productID: product.product,
      sku: product.sku,
      url: product.uri,
    };
  });
};

export const getProductJSON = (product: any, productUri: string) => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Product',
    brand: product.brandName,
    category: product?.categoryUri,
    color: product.variantName,
    description: product.metaDescription,
    fullPrice: product.priceBeforeDiscountAsNumber,
    ga4: getGA4ProductSchema(product),
    image: `${get(product, 'media.full.0')}`,
    name: product.name,
    offers: {
      '@type': 'AggregateOffer',
      highPrice: product.priceAsNumber,
      lowPrice: product.priceAsNumber,
      offerCount: `${product.items ? product.items.length : 0}`,
      offers: getProductItems(product, productUri),
    },
    priceReduction: product.priceBeforeDiscountAsNumber
      ? product.priceBeforeDiscountAsNumber - product.priceAsNumber
      : '',
    priceTax: '',
    productID: product.product,
    sku: product.sku,
  });
};

export const getProductListJSON = (products, id?: string, productCategoryName?: string) => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    itemListElement: products && getProductsList(products),
    itemListOrder: 'default',
    name: productCategoryName,
    numberOfItems: `${products && products.length}`,
    productListId: id || productCategoryName,
    url: `${isBrowser() ? window.location.pathname : ''}`,
  });
};

export const getCentraProductsJson = (productsBatches: any) => {
  const titles = Object.keys(productsBatches);

  const products = titles.flatMap(title => {
    return productsBatches[title];
  });

  const title = titles.toString();

  return getProductListJSON(products, title);
};
