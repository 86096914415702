import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useClickOutsideRef } from '@utils/global';
import cx from 'clsx';
import { useRouter } from 'next/router';
import type { OwnProps } from 'SearchButton';

import { Search as SearchIcon } from '../../../public/images/icons';

import Styles from './SearchButton.module.scss';

export const SearchButton: React.FC<OwnProps> = ({ onActivate }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const { ref: containerRef } = useClickOutsideRef<HTMLFormElement>(() => {
    setActive(false);
  });
  const [active, setActive] = useState(false);

  useEffect(() => {
    onActivate(active);
  }, [active]);

  const wrapperRef = useCallback(
    node => {
      if (node) {
        inputRef.current = node;
        inputRef.current.focus();
      }
    },
    [active],
  );

  const handleClick = () => {
    setActive(true);
    inputRef.current.focus();
  };

  const handleSearch = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (inputRef.current?.value) {
      router.push(`/search?q=${inputRef.current.value}`);
      setActive(false);
    }
  };

  const searchButtonWrapperStyles = cx({
    [Styles.wrapper]: true,
    [Styles.active]: active,
  });

  const searchInputClassNames = cx({
    [Styles.input]: true,
  });

  return (
    <form ref={containerRef} className={searchButtonWrapperStyles} onSubmit={handleSearch}>
      <input ref={wrapperRef} className={searchInputClassNames} placeholder="Search" />
      <button aria-label="Search" className={Styles.button} type="button" onClick={handleClick}>
        <SearchIcon className="inline-block" />
      </button>
    </form>
  );
};
