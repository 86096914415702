import get from 'lodash/get';

const getProductItems = (product: any, productUri: string, priceData: any) => {
  const priceSource = priceData && priceData.priceAsNumber ? priceData : product;

  return product.items.map((item: any) => {
    return {
      '@type': 'Offer',
      name: item.name,
      availability: item.stock === 'yes' ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      gtin13: item.ean,
      itemCondition: 'https://schema.org/NewCondition',
      price: priceSource.priceAsNumber,
      priceCurrency: getCurrencyByPricelistId(priceData.pricelistId),
      sku: product.sku,
      url: `${process.env.NEXT_PUBLIC_SITEMAP_DOMAIN_URL}/${productUri || ''}`,
    };
  });
};

const getProductsList = (products: any) => {
  return products.map((product: any, index: number) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      category: product.google_merchant_category,
      name: product.name,
      image: `${get(product, 'media.full.0')}`,
      url: `${process.env.NEXT_PUBLIC_SITEMAP_DOMAIN_URL}/${product.uri}`,
    };
  });
};

function getProductPrices(product: any) {
  return product.prices && product.prices.length ? product.prices : [product];
}

function prepareOffers(prices: any, product: any, productUri?: string) {
  return prices.map((priceData: any) => {
    return {
      '@type': 'AggregateOffer',
      highPrice: priceData.priceAsNumber,
      lowPrice: priceData.priceAsNumber,
      priceCurrency: getCurrencyByPricelistId(priceData.pricelistId),
      offerCount: `${product.items ? product.items.length : 0}`,
      offers: getProductItems(product, productUri || product.uri, priceData),
    };
  });
}

function getCurrencyByPricelistId(pricelistId) {
  const currencies = {
    20: 'USD',
    28: 'EUR',
    27: 'NOK',
    19: 'SEK',
  };

  if (currencies[pricelistId]) {
    return currencies[pricelistId];
  }

  return null;
}

export const getProductSchemaJSON = (product: any, productUri: string) => {
  const productPrices = getProductPrices(product);

  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Product',
    sku: product.sku,
    productID: product.product,
    description: product.metaDescription,
    name: product.name,
    brand: product.brandName,
    category: product.google_merchant_category,
    image: `${get(product, 'media.full.0')}`,
    color: product.variantName,
    offers: prepareOffers(productPrices, product, productUri),
  });
};

export const getProductListSchemaJSON = (products: any, productCategoryName: string) => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    url: `${typeof window !== `undefined` ? `${window.location.href}` : ''}`,
    numberOfItems: `${products.length}`,
    name: `${productCategoryName}`,
    itemListOrder: 'default',
    itemListElement: products && getProductsList(products),
  });
};

export const getCentraProductsSchemaJson = (productsBatches: any) => {
  const titles = Object.keys(productsBatches);

  const products = titles.flatMap(title => {
    return productsBatches[title];
  });

  const title = titles.toString();

  return getProductListSchemaJSON(products, title);
};

export const getPageSchemaJSON = (page: any) => {
  const { slug, title } = page;

  const preProcessSlug = (uri: string) => {
    if (['home', 'homepage'].includes(uri)) {
      return '';
    }

    if (uri === 'community') {
      return 'community';
    }

    return `page/${uri}`;
  };

  const preProcessTitle = (pageTitle: string, uri: string) => {
    if (uri === 'home' && process.env.NEXT_PUBLIC_HOME_PAGE_TITLE) {
      return process.env.NEXT_PUBLIC_HOME_PAGE_TITLE;
    }

    return title;
  };

  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: `${process.env.NEXT_PUBLIC_SITEMAP_DOMAIN_URL}/${preProcessSlug(slug)}`,
    name: preProcessTitle(title, slug),
    description: page.metaDescription || '',
  });
};

export const getCentraPageSchemaJSON = (desc: string) => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: `${process.env.NEXT_PUBLIC_URL}`,
    name: `${process.env.NEXT_PUBLIC_BRAND}`,
    description: `${desc}`,
  });
};

export const getBlogPostSchemaJSON = (post: any) => {
  const { title, slug, content, cover } = post;
  let parsedContent = JSON.parse(content);
  parsedContent = get(parsedContent, 'content');

  const postSchema = {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    url: `${process.env.NEXT_PUBLIC_SITEMAP_DOMAIN_URL}/community/post/${slug}`,
    headline: title,
    articleBody: null,
    image: null,
  };

  if (parsedContent) {
    const articleBody = parsedContent.map((contentData: any) => {
      return contentData.content.map((data: any) => {
        if (data.content) {
          return data.content.map((subContent: any) => {
            return subContent.value;
          });
        }
        return data.value;
      });
    });

    if (articleBody.length) {
      postSchema.articleBody = articleBody.toString();
    }
  }

  if (cover) {
    postSchema.image = get(cover, ['file', 'url']);
  }

  return JSON.stringify(postSchema);
};
