import React, { useEffect, useState } from 'react';

import { isBrowser } from '@core-utils/global';
import type { OwnProps } from 'CartInfo';
import cx from 'clsx';

import Styles from './CartInfo.module.scss';

export const CartInfo: React.FC<OwnProps> = () => {
  const [totalPrice, setTotalPrice] = useState<string | undefined>();

  const cartInfoStyles = cx({
    [Styles.container]: true,
  });

  const getStoreInfo = event => {
    if (isBrowser() && window.store && event.detail?.action?.payload?.selection?.totals?.itemsTotalPrice) {
      setTotalPrice(event.detail?.action?.payload?.selection?.totals?.itemsTotalPrice);
    }
  };

  useEffect(() => {
    window.addEventListener('gc/FETCH_SELECTION_SUCCESS', getStoreInfo);
    return () => {
      window.removeEventListener('gc/FETCH_SELECTION_SUCCESS', getStoreInfo);
    };
  }, []);

  return (
    <div className={cartInfoStyles}>{totalPrice && <h5 className={Styles.title}>Total amount {totalPrice}</h5>}</div>
  );
};
