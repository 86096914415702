import React, { Fragment } from 'react';

import cx from 'clsx';

import type { HorizontalPositions, OwnProps, VerticalPositions } from './types';

import Styles from './ShortText.module.scss';

export const ShortText: React.FC<OwnProps> = ({ shortText, position, link, textSize, color }) => {
  const [horizontal, vertical] = position?.split('-') as [HorizontalPositions, VerticalPositions];
  const textPositionClass = cx(Styles.shortText, Styles[horizontal], Styles[vertical]);
  const linkPosition = link?.[0].linkPosition || 'middle-center';
  const [linkHorizontal, linkVertical] =
    typeof (linkPosition === 'string') && (linkPosition.split('-') as [HorizontalPositions, VerticalPositions]);
  const linkPositionClass = cx(Styles.shortText, Styles[linkHorizontal], Styles[linkVertical]);
  const linkText = link?.[0]?.linkText;
  const linkColor = link?.[0]?.textColor;
  const linkTextSize = link?.[0]?.textSize;

  return (
    <Fragment>
      {shortText && (
        <span style={{ fontSize: `${textSize}px`, color, lineHeight: '120%' }} className={textPositionClass}>
          {shortText}
        </span>
      )}
      {link?.[0]?.linkText && (
        // the actual link is on the whole picture
        <div className={linkPositionClass} style={{ color: linkColor }}>
          <span style={{ fontSize: `${linkTextSize}px`, color: linkColor, lineHeight: '120%' }}>{linkText}</span>
        </div>
      )}
    </Fragment>
  );
};
