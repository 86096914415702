import React, { useRef } from 'react';

import { Newsletter } from '@components/components';
import { Link } from '@core-frontend/components/Link';

import { Facebook, Instagram, Twitter } from '../../../../public/images/icons/icons';
import { useNewsletterStore } from '../../../../store/newsletterStore';
import type { ContactFormData, Media } from '../../../../types';
import type { ContentfulMenuElement } from '../../../../types/contentful/menu';

import Styles from './MobileFooter.module.scss';

type OwnProps = {
  menu: ContentfulMenuElement[];
  newsletterMedias: Media;
  newsletterContent?: ContactFormData;
  newsletterSubscription?: (variables: {}) => Promise<any>;
  renderCookieModalButton: () => JSX.Element;
};

export const MobileFooter: React.FC<OwnProps> = ({
  menu,
  newsletterSubscription,
  newsletterMedias,
  newsletterContent,
  renderCookieModalButton,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { toggleNewsletter } = useNewsletterStore();

  const findSocial = menu.find(element => {
    if (element.title?.toLowerCase() === 'social media' && element.__typename === 'CfMenus') {
      return element.menu;
    }
  });

  const findLogo = (linkText: string) => {
    switch (linkText) {
      case 'Facebook': {
        return <Facebook />;
      }
      case 'Instagram': {
        return <Instagram />;
      }
      case 'Twitter': {
        return <Twitter />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={Styles.wrapper} ref={wrapperRef}>
      <div className={Styles.linksWrapper}>
        <div className={Styles.links}>
          {menu.map((link, index) => {
            if (link.__typename === 'CfMenus') {
              return null;
            }
            if (link.url === '#newsletter') {
              return (
                <div key={`footer-link-${index}`} className={Styles.link}>
                  <Link
                    to={link.url}
                    onClick={event => {
                      event.preventDefault();
                      toggleNewsletter(true);
                    }}
                  >
                    Newsletter
                  </Link>
                </div>
              );
            }
            return (
              <div className={Styles.link} key={`footer-link-${index}`}>
                <Link to={link.url} linkToSameSite={false}>
                  {link.linkText}
                </Link>
              </div>
            );
          })}
          {renderCookieModalButton()}
        </div>

        <div className={Styles.socialMedia}>
          {findSocial.__typename === 'CfMenus' &&
            findSocial?.menu?.map((element, index) => {
              if (element.__typename === 'CfMenus') {
                return null;
              }
              const { linkText, url } = element;
              return (
                <a title={linkText} href={url} key={`footer-social-link-${index}`}>
                  {findLogo(linkText)}
                </a>
              );
            })}
        </div>
      </div>
      <div className={Styles.copyright}>
        <span>&copy; BLK DNM</span>
      </div>
      <Newsletter
        newsletterContent={newsletterContent}
        newsletterMedias={newsletterMedias}
        newsletterSubscription={newsletterSubscription}
      />
    </div>
  );
};
