import React, { useRef } from 'react';

import cx from 'clsx';
import { useRouter } from 'next/router';

import { Search as SearchIcon } from '../../../public/images/icons/icons';

import Styles from './Search.module.scss';

interface OwnProps {
  menuToggle: () => void;
}

export const Search: React.FC<OwnProps> = props => {
  const { menuToggle } = props;
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);
  const formClassNames = cx(Styles.form);
  const inputClassNames = cx(Styles.input);
  const buttonClassNames = cx(Styles.button);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {};

  const handleSearch = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (inputRef.current?.value) {
      router.push(`/search?q=${inputRef.current.value}`);
    }
    menuToggle();
  };

  return (
    <form autoComplete="off" autoCorrect="off" className={formClassNames} onSubmit={handleSearch}>
      <input
        autoComplete="off"
        autoCorrect="off"
        type="text"
        ref={inputRef}
        id="search-input"
        className={inputClassNames}
        placeholder="Search..."
        onChange={handleInputChange}
      />
      <button type="button" className={buttonClassNames}>
        <SearchIcon />
      </button>
    </form>
  );
};
