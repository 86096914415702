import React from 'react';

import cx from 'clsx';

import type { OwnProps } from './types';

import * as Styles from './RichHeading.module.scss';

export const RichHeading: React.FC<React.PropsWithChildren<OwnProps>> = ({ size, children, withSpace }) => {
  if (!children) {
    return null;
  }

  const richHeadingClassNames = cx(Styles.richHeading, {
    [Styles.withSpace]: withSpace,
    [(Styles as any)[`h${size}`]]: size != null,
  });
  switch (size) {
    case 1: {
      return <h1 className={richHeadingClassNames}>{children}</h1>;
    }
    case 2: {
      return <h2 className={richHeadingClassNames}>{children}</h2>;
    }
    case 3: {
      return <h3 className={richHeadingClassNames}>{children}</h3>;
    }
    case 4: {
      return <h4 className={richHeadingClassNames}>{children}</h4>;
    }
    case 5: {
      return <h5 className={richHeadingClassNames}>{children}</h5>;
    }
    default: {
      return <h6 className={richHeadingClassNames}>{children}</h6>;
    }
  }
};
