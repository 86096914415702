import React from 'react';

import { Block } from '@components/components';
import cx from 'clsx';

import { RichText } from '..';

import Styles from './TextEditor.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    width?: 'fullscreen' | 'container';
    content: string;
    headerText: string;
    textAlign: 'center' | 'left' | 'justify' | 'right';
    removeEmptyLines?: 'yes' | 'no' | null;
  };
};

export const TextEditor: React.FC<OwnProps> = ({ module }) => {
  if (!module) {
    return null;
  }

  const { content, width, textAlign, slug, removeEmptyLines } = module;

  const renderContent = (content: string) => {
    const contentText = content && JSON.parse(content);
    const shouldSkipEmptyParagraphs = removeEmptyLines === 'yes';

    const containerClassNames = cx({
      [Styles.container]: true,
      [Styles.cleaned]: shouldSkipEmptyParagraphs,
    });

    return (
      <div className={containerClassNames} style={{ ...(textAlign && { textAlign: textAlign }) }}>
        {RichText(contentText, shouldSkipEmptyParagraphs)}
      </div>
    );
  };

  return (
    <Block width={width} theme="light" id={slug}>
      <div className={Styles.wrapper}>{renderContent(content)}</div>
    </Block>
  );
};

export default TextEditor;
