import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import { useCurrentWidth } from '@utils/hooks';
import cx from 'clsx';

import type { MediaImage } from '../../../types';
import { LazyImage } from '../LazyImage/LazyImage';

import type { OwnProps } from './types';

import Styles from './ImageBanner.module.scss';

export const ImageBanner: React.FC<JSX.IntrinsicElements['div'] & OwnProps> = ({
  media,
  className,
  priority = false,
}) => {
  const { mobileImage, desktopImage, richContent, mobileAspect, desktopAspect, shadow = false } = media || {};
  const containerRef = useRef(null);
  const [componentWidth, setComponentWidth] = useState();
  const windowWidth = useCurrentWidth();

  const imageWidth = componentWidth;

  const wrapperRef = useCallback(node => {
    if (node) {
      containerRef.current = node;
      setComponentWidth(node.offsetWidth);
    }
  }, []);

  /*   useEffect(() => {
    window.objectFitPolyfill();
  }, []); */

  useEffect(() => {
    if (containerRef.current) {
      setComponentWidth(containerRef.current.offsetWidth);
    }
  }, [windowWidth]);

  const renderImage = (data?: MediaImage, aspect?: string) => {
    if (!data) {
      return null;
    }

    const {
      file: {
        url,
        details: {
          image: { width, height },
        },
      },
      title,
    } = data;

    const [aspectWidth, aspectHeight] = (aspect || `${width}:${height}`).split(':');
    const finalAspect = (parseInt(aspectHeight) / parseInt(aspectWidth)) * 100;

    return (
      <div
        ref={wrapperRef}
        className={imageBannerClassNames}
        {...(finalAspect && { style: { paddingTop: `${finalAspect}%` } })}
      >
        <LazyImage
          sources={true}
          priority={priority}
          height={height}
          width={imageWidth}
          src={`${url}?fit=scale&w=${imageWidth}`}
          alt={title || ''}
        />
      </div>
    );
  };

  const imageBannerClassNames = cx({
    [Styles.imageContainer]: true,
    [Styles.shadow]: shadow,
    ...(richContent && 'contentTheme' in richContent && { [Styles[richContent.contentTheme]]: true }),
    ...(className && { [className]: true }),
  });

  return (
    <Fragment>
      <OnMobileAndTabletOnly>
        {(mobileImage || desktopImage) && renderImage(mobileImage || desktopImage, mobileAspect)}
      </OnMobileAndTabletOnly>
      <OnDesktopOnly>
        {(mobileImage || desktopImage) && renderImage(desktopImage || mobileImage, desktopAspect)}
      </OnDesktopOnly>
    </Fragment>
  );
};
