/* eslint-disable class-methods-use-this */
import Cookies from 'js-cookie';

import { CookiesType } from '../types';

import { languageMap } from './constants/languageMap';

export const COOKIE_LANGUAGE = 'language';
export const CENTRA_TOKEN = 'token';
export const COUNTRY_CODE = 'countryCode';
export const COUNTRY_NAME = 'countryName';
export const MARKET = 'market';
export const PRICELIST = 'pricelist';
export const XFF = 'xff';
export const CJ_EVENT = 'cje';

class CookieUtils {
  consentCookie = {
    name: 'CookieConsentDialog',
    value: 'hidden',
  };

  getXff(cookies: CookiesType) {
    return cookies[XFF];
  }

  getToken(cookies: CookiesType) {
    return cookies[CENTRA_TOKEN];
  }

  getCfLanguage(cookies: CookiesType) {
    const sessionLanguage = cookies[COOKIE_LANGUAGE]!;

    return (languageMap as any)[sessionLanguage] || languageMap.en;
  }

  getCentraToken(cookies: any) {
    return cookies[CENTRA_TOKEN];
  }

  getCountryCode(cookies: CookiesType) {
    return cookies[COUNTRY_CODE];
  }

  getCountryName(cookies: CookiesType) {
    return cookies[COUNTRY_NAME];
  }

  getMarket(cookies: CookiesType) {
    return cookies[MARKET];
  }

  getCJEvent(cookies: CookiesType) {
    return cookies[CJ_EVENT];
  }

  oneYearFromNowDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  setCookie = (name, value, expires, path) => {
    Cookies.set(name, value, { expires, path });
  };

  shouldDisplayCookieBar = (name, value) => {
    const hasCookie = Cookies.get(name) && Cookies.get(name) === value;

    return !hasCookie;
  };
}

export const cookieUtils = new CookieUtils();
