import React from 'react';

import cx from 'clsx';
import Link from 'next/link';

import Styles from './Breadcrumbs.module.scss';

export type BreadcrumbLink = {
  breadcrumb: string;
  href: string;
};

type OwnProps = {
  linkedPages?: any;
  first?: string;
  breadcrumbs: BreadcrumbLink[];
  skip?: string;
  color?: 'black' | 'white';
};

const convertBreadcrumb = breadcrumb => {
  return breadcrumb.replace(/-/g, ' ').replace(/#/g, ' / ');
};

export const Breadcrumbs: React.FC<OwnProps> = ({ first = 'Home', breadcrumbs, skip, color = 'black' }) => {
  const breadcrumbsClassNames = cx({
    [Styles[color]]: color,
  });

  if (!breadcrumbs || breadcrumbs[0]?.breadcrumb === '') {
    return null;
  }
  return (
    <div className={breadcrumbsClassNames} aria-label="breadcrumbs">
      <ol className={Styles.wrapper}>
        {breadcrumbs && breadcrumbs[0]?.breadcrumb !== '' && (
          <li>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/">{first}</a>
          </li>
        )}
        {breadcrumbs.map((element, index) => {
          if (element.breadcrumb !== '' && element.breadcrumb !== skip) {
            return (
              <li
                key={`breadcrumb-list-${index}`}
                className={cx(Styles.element, {
                  [Styles.noFirst]: index === 0 && first === '',
                })}
              >
                <Link href={element.href} legacyBehavior>
                  <a>{`${convertBreadcrumb(element.breadcrumb)}`}</a>
                </Link>
              </li>
            );
          }
          return null;
        })}
      </ol>
    </div>
  );
};
