import React from 'react';

import type { OwnProps } from 'Block';
import cx from 'clsx';

import Styles from './Block.module.scss';

export const Block: React.FC<JSX.IntrinsicElements['section'] & OwnProps> = props => {
  const { width, theme, children, className, id, noAnimate = false } = props;

  const blockClassNames = cx({
    [Styles.wrapper]: true,
    [Styles.noAnimate]: noAnimate,
    [Styles[theme || 'dark']]: true,
    ...(className && { [className]: true }),
  });

  const contentClassNames = cx({
    [Styles.content]: true,
    [Styles[width || 'container']]: true,
  });

  return (
    <section className={blockClassNames} id={id}>
      <div className={contentClassNames}>{children}</div>
    </section>
  );
};
