import { create } from 'zustand';

interface NewsletterState {
  isNewsletterOpen: boolean;
  toggleNewsletter: (state: boolean) => void;
}

export const useNewsletterStore = create<NewsletterState>(set => ({
  isNewsletterOpen: false,
  toggleNewsletter: (state: boolean) => set(() => ({ isNewsletterOpen: state })),
}));
