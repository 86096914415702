import { CENTRA_TOKEN, CJ_EVENT, COOKIE_LANGUAGE, COUNTRY_CODE, MARKET, PRICELIST } from '@core-server/CookieUtils';
import Cookie from 'js-cookie';

class CookieService {
  getMarket() {
    return Cookie.get(MARKET);
  }

  getCountryCode() {
    return Cookie.get(COUNTRY_CODE);
  }

  getCookieLanguage() {
    return Cookie.get(COOKIE_LANGUAGE);
  }

  getPriceList() {
    return Cookie.get(PRICELIST);
  }

  getCJEvent() {
    return Cookie.get(CJ_EVENT);
  }

  getToken() {
    return Cookie.get(CENTRA_TOKEN);
  }
}

export const cookieService = new CookieService();
