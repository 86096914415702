import React from 'react';

import { Link } from '@core-frontend/components/Link';
import cx from 'clsx';

import type { OwnProps } from './types';

import Styles from './GridLink.module.scss';

export const GridLink: React.FC<OwnProps> = ({ url, linkText, onClick, isRelatedCategory }) => {
  return (
    <div className={cx(Styles.header, { [Styles.relatedCategory]: isRelatedCategory })}>
      {url !== null && (
        <Link className={Styles.link} to={url}>
          {linkText}
        </Link>
      )}
      {url === null && (
        <button type="button" className={Styles.link} onClick={onClick}>
          {linkText}
        </button>
      )}
    </div>
  );
};
