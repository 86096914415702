import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import type { FilterProduct } from '@components/renderers/ContentfulPagesRenderer/components/CentraProducts/CentraProducts';
import { useClickOutsideRef } from '@utils/global';
import cx from 'clsx';
import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';

import CentraCategoryPageContext from '../../contexts/CentraCategoryPageContext';
import { CategoryBreadcrumbs } from '../CategoryBreadcrumbs/CategoryBreadcrumbs';
import { Filters } from '..';

import type { OwnProps } from './types';

import Styles from './TileMenu.module.scss';

interface FilterConfig {
  name: string;
  type: string;
  items: string[];
  multiselect: boolean;
  definedColors?: Record<string, string>;
  selected: string[];
  defaultFilter?: 'popular';
}

export type FilterTypes = 'lengths' | 'sizes' | 'colors' | 'sort' | 'types';
export type FiltersConfig = Record<FilterTypes, FilterConfig>;

const getParams = (products: FilterProduct[]) => {
  const sizes = new Set<string>();
  const lengths = new Set<string>();
  const colors = new Set<string>();
  const types = new Set<string>();
  const definedColors = {};
  products.forEach(product => {
    const { items, isRaffle, swatch } = product;
    if (swatch) {
      const desc = swatch.desc.trim().replaceAll(' ', '-').toLowerCase();
      const hex = swatch.hex.trim().replaceAll(' ', '-').toLowerCase();
      colors.add(desc);
      definedColors[desc] = hex;
    }
    const stockItems = items.filter(item => isRaffle || item.stock !== 'no');
    stockItems.forEach(({ name }) => {
      const isCombinedSize = name.match(/\d+x\d+/);
      if (name) {
        if (isCombinedSize) {
          const [size, length] = name.trim().split('x');
          sizes.add(size);
          lengths.add(length);
        } else {
          sizes.add(name.trim());
        }
      }
    });
    sizes.add('XXL');
  });
  return {
    sizes: [...sizes].sort((item1, item2) => {
      const nonNumericalSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XL', 'XXXL'];
      const isItem1Custom = nonNumericalSizes.includes(item1);
      const isItem2Custom = nonNumericalSizes.includes(item2);
      if (isItem1Custom && isItem2Custom) {
        return item2.localeCompare(item1);
      }
      return item1.localeCompare(item2);
    }),
    lengths: [...lengths].sort(),
    colors: [...colors],
    types: [...types],
    definedColors,
  };
};

const getInitialFilters = (products: FilterProduct[], selectedFilters: any): FiltersConfig => {
  const { sizes, lengths, colors, definedColors, types = [] } = getParams(products);

  return {
    sizes: {
      name: 'Size',
      type: 'text',
      items: sizes,
      multiselect: true,
      selected: selectedFilters.sizes || [],
    },
    lengths: {
      name: 'Lengths',
      type: 'text',
      items: lengths,
      multiselect: true,
      selected: selectedFilters.lengths || [],
    },
    colors: {
      name: 'Colors',
      type: 'color',
      items: colors,
      multiselect: true,
      definedColors,
      selected: selectedFilters.colors || [],
    },
    sort: {
      name: 'Sort by',
      type: 'text',
      multiselect: false,
      items: ['popular', 'A-Z', 'Z-A', 'Price Low to Hight', 'Price High to Low'],
      selected: selectedFilters.sort || [],
      defaultFilter: 'popular',
    },
    types: {
      name: 'Type',
      type: 'text',
      multiselect: true,
      items: ['skinny', 'bootcut', 'straight', 'regular', 'tapered'],
      selected: selectedFilters.types || [],
    },
  };
};

export const TileMenu: React.FC<OwnProps> = ({ className, title }) => {
  const centraContext = React.useContext(CentraCategoryPageContext);
  const { products = [], selectedFilters, filteredProducts = [] } = centraContext;
  const [openedFilters, setOpenedFilters] = useState(false);
  const [filters, setFilters] = useState(getInitialFilters(products, selectedFilters));
  const [currentlySelectedFilters, setCurrentlySelectedFilters] = useState(filters);
  const { ref: outsideRef } = useClickOutsideRef<HTMLDivElement>(() => {
    setOpenedFilters(false);
  });
  const router = useRouter();
  const tileMenuClassNames = cx({
    [Styles.wrapper]: true,
    ...(className && { [className]: true }),
  });

  const isSearchPage = title && title !== '';

  useEffect(() => {
    const newFilters = getInitialFilters(products, selectedFilters);
    if (!isEqual(filters, newFilters)) {
      setFilters(newFilters);
      setCurrentlySelectedFilters(newFilters);
    }
  }, [products, filters]);

  useEffect(() => {
    if (!openedFilters) {
      setCurrentlySelectedFilters(getInitialFilters(products, selectedFilters));
    }
  }, [openedFilters]);

  const onFilterChange = (param: FilterTypes, item: string) => (event: any) => {
    const selectedFilter = getNewSelectedFilter(currentlySelectedFilters[param], item);

    setCurrentlySelectedFilters({
      ...currentlySelectedFilters,
      [param]: selectedFilter,
    });
  };

  const getNewSelectedFilter = (filter: FilterConfig, item: string) => {
    const selectedFilter = { ...filter };
    if (selectedFilter.multiselect) {
      if (selectedFilter.selected.includes(item)) {
        selectedFilter.selected = selectedFilter.selected.filter(element => element != item);
      } else {
        selectedFilter.selected.push(item);
      }
    } else {
      selectedFilter.selected = selectedFilter.selected.includes(item) ? [] : [item];
    }
    return selectedFilter;
  };

  const onReset = () => {
    const allFilters = { ...currentlySelectedFilters };
    const newFilters = Object.keys(allFilters)
      .map(filterName => ({
        [filterName]: {
          ...allFilters[filterName],
          selected: [],
        },
      }))
      .reduce((acc, current) => ({ ...acc, ...current }), {}) as FiltersConfig;
    if (!isEqual(currentlySelectedFilters, newFilters)) {
      setCurrentlySelectedFilters(newFilters);
    }
  };

  const onApply = (filters?: FiltersConfig) => {
    const chosenFilters = filters || currentlySelectedFilters;
    addFilterParamsToUrl(chosenFilters);
    setOpenedFilters(false);
    setFilters(chosenFilters);
  };

  const stringifyFilters = (filters: FiltersConfig, searchParam?: string) => {
    const stringifyFilter = Object.keys(filters)
      .map(filterName => {
        const { selected } = filters[filterName];
        if (selected.length === 0) {
          return null;
        }
        return `${filterName}=${selected.join(',').toLowerCase().replaceAll(' ', '-')}`;
      })
      .filter(Boolean)
      .join('&');
    return `${stringifyFilter}${searchParam ? `&q=${searchParam}` : ''}`;
  };

  const addFilterParamsToUrl = (filters: FiltersConfig) => {
    const searchParam = Array.isArray(router?.query?.q) ? router.query.q.join(' ') : router.query.q;
    const selectedFilters = stringifyFilters(filters, searchParam);
    const [pageUri] = router.asPath.split('?');
    const param = `${pageUri}${selectedFilters ? `?${selectedFilters}` : ''}`;
    router.replace(param, undefined, { shallow: true });
  };

  return (
    <div className="relative">
      <div className="container mb-4">
        <div className={tileMenuClassNames} ref={outsideRef}>
          <div className={Styles.subcategoriesWrapper}>
            <div className={Styles.subcategories}>
              <div className={Styles.breadcrumbs}>
                <CategoryBreadcrumbs />
              </div>
            </div>
            <div className={Styles.container}>
              <div className={Styles.keyword}>showing {filteredProducts.length} garments</div>
              {isSearchPage && <div className={Styles.keyword}>keyword: {title}</div>}
              <button
                onClick={() => {
                  setOpenedFilters(!openedFilters);
                }}
                className={cx(Styles.filter, {
                  [Styles.searchFilter]: isSearchPage,
                })}
              >
                Filter & Sort
              </button>
            </div>
          </div>
          {openedFilters && (
            <TransitionGroup>
              <CSSTransition timeout={100} classNames="fade" appear={true}>
                <button
                  onClick={() => {
                    onApply();
                  }}
                  className={openedFilters ? cx(Styles.apply, Styles.applyVisible) : Styles.apply}
                >
                  Apply filter
                </button>
              </CSSTransition>
            </TransitionGroup>
          )}
          <Filters
            filters={currentlySelectedFilters}
            onFilterChange={onFilterChange}
            onReset={onReset}
            onApply={onApply}
            opened={openedFilters}
            isSearchPage={isSearchPage}
          />
        </div>
      </div>
    </div>
  );
};
