import React, { Fragment } from 'react';

import { Block, ImageBanner } from '@components/components';
import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import { Link } from '@core-frontend/components';
import cx from 'clsx';

import type { Media } from '../../../../../types';
import type { RichContent } from '../../../../../types/contentful/RichContent';

import { TripplePromoTextMediaContent } from './components/TriplePromoTextMediaContent/TripplePromoTextMediaContent';

import Styles from './TripplePromoText.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    imageRight: Media;
    imageLeft: Media;
    richContent?: RichContent | null;
    side?: 'left' | 'right';
    width?: 'fullscreen' | 'container';
    theme?: 'light' | 'dark';
  };
  priority?: boolean;
};

export const TripplePromoText: React.FC<OwnProps> = ({ module, priority }) => {
  if (!module) {
    return null;
  }

  const { imageLeft, imageRight, richContent, width, theme, side, slug } = module;

  const { links } = richContent || {};
  const linkUrl = links && links[0]?.url;
  const linkText = links && links[0]?.title;

  const renderMedia = (media: Media) => {
    const { desktopImage, mobileImage } = media || {};

    const containerClassNames = cx({
      [Styles.container]: true,
      [Styles[side || 'right']]: true,
    });

    return (
      <div className={containerClassNames}>
        {(desktopImage || mobileImage) && <ImageBanner priority={priority} media={media} />}
      </div>
    );
  };

  const wrapperClassNames = cx({
    [Styles.wrapper]: true,
    ...(richContent && 'contentTheme' in richContent && { [Styles[richContent.contentTheme]]: true }),
  });

  const renderContent = () => (
    <>
      <div className={wrapperClassNames}>
        {side === 'left' ? (
          <Fragment>
            <OnDesktopOnly>
              {richContent && <TripplePromoTextMediaContent className={Styles.contentPadding} config={richContent} />}
            </OnDesktopOnly>
            {renderMedia(imageRight)}
            {renderMedia(imageLeft)}
          </Fragment>
        ) : (
          <Fragment>
            {renderMedia(imageLeft)}
            {renderMedia(imageRight)}
            <OnDesktopOnly>
              {richContent && <TripplePromoTextMediaContent className={Styles.contentPadding} config={richContent} />}
            </OnDesktopOnly>
          </Fragment>
        )}
      </div>
      <OnMobileAndTabletOnly>
        <div className={wrapperClassNames}>
          {richContent && <TripplePromoTextMediaContent className={Styles.contentPadding} config={richContent} />}
        </div>
      </OnMobileAndTabletOnly>
    </>
  );

  return (
    <Block width={width} theme={theme} id={slug}>
      {linkUrl ? (
        <Link className={Styles.containerLink} to={linkUrl} {...(linkText && { 'aria-label': linkText })}>
          {renderContent()}
        </Link>
      ) : (
        renderContent()
      )}
    </Block>
  );
};

export default TripplePromoText;
