import { gql } from '@apollo/client';

import { PricesFragment } from './PricesFragment';

export const ProductPricesFragment = gql`
  ${PricesFragment}
  fragment ProductPrices on Product {
    prices {
      ...Prices
    }
  }
`;
