import { gql } from '@apollo/client';

export const pageAndCategoryQuery = gql`
  query Categories {
    categories {
      category
      name
      uri
    }
    cfPages(limit: 1000) {
      pageTitle
      slug
    }
  }
`;

export const navigationBuilderQuery = gql`
  query cfNavigationBuilders($query: EntryQueryType) {
    cfNavigationBuilders(q: $query) {
      navigationBuilder
    }
    categories {
      category
      name
      uri
    }
    cfPages(limit: 1000) {
      pageTitle
      slug
    }
  }
`;

export const galleriesQuery = gql`
  query cfGalleries($query: EntryQueryType) {
    cfGalleries(q: $query) {
      media {
        ... on CfMedia {
          slug
          desktopImage {
            title
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
          mobileImage {
            title
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
          video {
            file {
              url
            }
          }
          links {
            title
            linkText
            url
            external
            isTextPage
            textColor
            textSize
          }
        }
        ... on CfCentraProducts {
          headerText
          centraProducts
          link {
            title
            linkText
            url
            external
            isTextPage
          }
        }
      }
    }
  }
`;
