import React from 'react';

import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import cx from 'clsx';

import type { Spacer as SpacerType } from '../../../../../types/contentful/Spacer';

import Styles from './Spacer.module.scss';

interface OwnProps {
  module: SpacerType;
  width?: 'fullscreen' | 'container';
  placeholder?: string;
}

export const Spacer: React.FC<JSX.IntrinsicElements['hr'] & OwnProps> = ({ module, className, style, ...rest }) => {
  const { desktopSpace = '0', mobileSpace = '0' } = module;

  const spacerClassNames = cx(Styles.container, className);

  return (
    <>
      <OnDesktopOnly>
        <hr className={spacerClassNames} style={{ flex: `0 0 ${desktopSpace}`, ...style }} {...rest} />
      </OnDesktopOnly>
      <OnMobileAndTabletOnly>
        <hr className={spacerClassNames} style={{ flex: `0 0 ${mobileSpace}`, ...style }} {...rest} />
      </OnMobileAndTabletOnly>
    </>
  );
};
