import React, { useEffect } from 'react';

import cx from 'clsx';

import type { OwnProps } from './types';

import * as Styles from './Layout.module.scss';

type ExternalProps = {
  menu: boolean;
};

export const Layout: React.FC<React.PropsWithChildren<OwnProps & ExternalProps>> = ({ menu, children, className }) => {
  const mainContainerStyles = cx(Styles.container, className);

  useEffect(() => {
    const captchaBadge = document.querySelector('.grecaptcha-badge');
    const raffleForm = document.getElementById('raffles-form');
    if (!!captchaBadge && !raffleForm) {
      captchaBadge.classList.add('hidden');
    }
  });

  return (
    <main className={mainContainerStyles}>
      <div className={Styles.portal} id="portal" />
      {children}
    </main>
  );
};
