import React from 'react';

import { Bag as BagIcon } from '../../../public/images/icons/icons';

export function EmptyCart() {
  return (
    <div className="my-8 space-y-4 text-center lg:my-16">
      <BagIcon className="mx-auto text-muted-foreground" />
      <div className="space-y-1">
        <p className="font-bold uppercase tracking-widest text-black">Your cart is empty</p>
        <p className="text-sm text-muted-foreground">Looks like you haven&apos;t added anything to your cart yet</p>
      </div>
    </div>
  );
}
