import React from 'react';
import { SlideDown } from 'react-slidedown';

import type { OwnProps } from 'Accordion';
import cx from 'clsx';

import { ChevronDown } from '../../../../public/images/icons';

import 'react-slidedown/lib/slidedown.css';
import Styles from './Accordion.module.scss';

export const Accordion: React.FC<OwnProps> = ({
  onToggle,
  expanded,
  title,
  children,
  expandButton,
  collapseButton,
  className,
  version = 'horizontal',
}) => {
  const toggleAccordion = () => {
    onToggle && onToggle();
  };

  const ExpandButton: React.FC<any> = expandButton
    ? ownProps => React.createElement(expandButton, { ...ownProps })
    : undefined;
  const CollapseButton: React.FC<any> = collapseButton
    ? ownProps => React.createElement(collapseButton, { ...ownProps })
    : undefined;

  return (
    <div className={cx(Styles.wrapper, Styles[version])}>
      <button type="button" className={Styles.button} onClick={toggleAccordion} aria-expanded={expanded}>
        <h4 className={cx(Styles.title, className)}>{title}</h4>
        {!expanded && ExpandButton && <ExpandButton />}
        {expanded && CollapseButton && <CollapseButton />}
        {!ExpandButton && !CollapseButton && (
          <ChevronDown
            className={cx(Styles.chevron, {
              [Styles.chevronExpanded]: expanded,
            })}
          />
        )}
      </button>
      {version === 'horizontal'
        ? children && <SlideDown transitionOnAppear={true}>{expanded ? children : null}</SlideDown>
        : children && expanded && <SlideDown transitionOnAppear={true}>{children}</SlideDown>}
    </div>
  );
};
