import React from 'react';

import { Block, ImageBanner } from '@components/components';
import { OnDesktopOnly, OnMobileAndTabletOnly } from '@components/layout-components';
import { Link } from '@core-frontend/components';
import cx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { Media, MediaLink } from '../../../../../types';

import Styles from './Instagram.module.scss';

export type OwnProps = {
  module: {
    slug: string | null;
    title: string;
    headerText: string;
    medias: MediaLink[];
    width?: 'fullscreen' | 'container';
    theme?: 'light' | 'dark';
  };
  priority?: boolean;
};

export const Instagram: React.FC<OwnProps> = ({ module, priority }) => {
  if (!module) {
    return null;
  }

  const { medias, theme, width, headerText, slug } = module;

  const mobileConfig = {
    slidesPerView: 4,
    spaceBetween: 0,
    loop: true,
    noSwiping: false,
    ...(medias.length <= 4 && {
      noSwiping: true,
    }),
  };

  const desktopConfig = {
    slidesPerView: 6,
    spaceBetween: 0,
    loop: true,
    noSwiping: false,
    ...(medias.length <= 6 && {
      noSwiping: true,
    }),
  };

  const renderImage = (image: Media) => <ImageBanner priority={priority} media={image} />;

  const renderMedia = (media: MediaLink) => {
    const { image, link } = media || {};
    const { desktopImage, mobileImage } = image || {};
    const url = link && link.url;

    const containerClassNames = cx({
      [Styles.container]: true,
    });

    return (
      <div className={containerClassNames}>
        {(desktopImage || mobileImage) && (url ? <Link to={url}>{renderImage(image)}</Link> : renderImage(image))}
      </div>
    );
  };

  const wrapperClassNames = cx({
    [Styles.wrapper]: true,
  });

  return (
    <Block width={width} theme={theme} id={slug}>
      <div className={Styles.header}>{headerText}</div>
      <div className={wrapperClassNames}>
        <div className={Styles.slider}>
          <OnMobileAndTabletOnly>
            <Swiper {...mobileConfig}>
              {medias.map((media, index) => (
                <SwiperSlide key={`instagram-mobile-item-${index}`}>{renderMedia(media)}</SwiperSlide>
              ))}
            </Swiper>
          </OnMobileAndTabletOnly>
          <OnDesktopOnly>
            <Swiper {...desktopConfig}>
              {medias.map((media, index) => (
                <SwiperSlide key={`instagram-desktop-item-${index}`}>{renderMedia(media)}</SwiperSlide>
              ))}
            </Swiper>
          </OnDesktopOnly>
        </div>
      </div>
    </Block>
  );
};

export default Instagram;
