import { gql } from '@apollo/client';

export const PricesFragment = gql`
  fragment Prices on Prices {
    price
    priceAsNumber
    priceBeforeDiscount
    priceBeforeDiscountAsNumber
    pricelistId
  }
`;
